
.product-line-item {
    position: relative;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    &:not(:last-child) {
        border-bottom: solid .0625rem $color-grey-2;
    }

    &.set--in-wishlist {
        .product-line-item__wishlist-add {
            display: none;
        }

        .product-line-item__wishlist-remove {
            display: inline-block;
        }
    }
}

.product-line-item--wishlist {
    &.set--removed-wishlist {
        .product-line-item__wishlist-revert {
            display: block;
        }

        .product-line-item__main {
            display: none;
        }
    }

    .product-line-item__unit-price {
        margin-top: .5rem;
    }

    .product-line-item__actions {
        margin-top: 1.5rem;
        margin-bottom: 2.5rem;
    }
}

.product-line-item--cart {
    .product-line-item__actions {
        margin-top: 3rem;
    }
}

    .product-line-item__details--readonly {
        padding-right: 4.5rem;
    }

    .product-line-item__header {
        margin-bottom: .25em;
        padding-right: .75em;
    }

    .product-line-item__footer {
        margin-top: auto;
    }

        .product-line-item__footer-item {
            &:not(:last-child) {
                margin-bottom: 2.5rem;
            }
        }

    .product-line-item__media {
        margin-right: 1.5rem;
    }

        .product-line-item__media-link {
            display: block;
        }

    .product-line-item__attribute,
    .product-line-item__option {
        &:not(:last-child) {
            margin-bottom: .25em;
        }
        &.final-sale {
            color: red;
        }
    }

    .product-line-item__actions {
        display: flex;
        margin-top: 1rem;
        white-space: nowrap;
    }

    .product-line-item__qty-form {
        max-width: 7.125rem;
    }

    .product-line-item__qty-pricing--readonly {
        .product-line-item__total-price {
            position: absolute;
            top: 1.25rem;
            right: 0;
        }
    }

        .product-line-item__remove {
            margin-left: auto;
        }

    .product-line-item__wishlist-remove,
    .product-line-item__wishlist-revert {
        display: none;
    }

@include media-query(medium-up) {
    .product-line-item--order,
    .product-line-item--wishlist {
        .product-line-item__qty-pricing {
            margin-top: 1.25rem;
        }
    }

    .product-line-item--cart {
        .product-line-item__details--editable {
            //Substract the width of the ".product-line-item__qty-pricing--editable" element.
            width: calc(100% - 12.5rem);
        }

        .product-line-item__qty-pricing--editable {
            width: 12.5rem;
        }
    }

    .product-line-item__details--editable {
        //Substract the width of the ".product-line-item__qty-pricing--editable" element.
        width: calc(100% - 10rem);
    }

    .product-line-item__qty-pricing--editable {
        position: absolute;
        width: 10rem;
        top: 1.25rem;

        .product-line-item--minicart & {
            right: 1.5rem;
        }

        .product-line-item--cart & {
            right: 0;
        }
    }

    .product-line-item__qty-form {
        display: flex;
        align-items: center;
    }

        .product-line-item__qty-label {
            margin-right: .25rem;
        }
}

@include media-query(medium) {
    .product-line-item__qty-pricing {
        margin-top: .5rem;
    }
}

@include media-query(small-up) {
    .product-line-item--minicart {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

        .product-line-item__action {
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
}

@include media-query(small) {
    .product-line-item--minicart {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .product-line-item--cart {
        .product-line-item__wishlist {
            order: 1;
            width: 100%;
        }
    }

        .product-line-item__actions {
            flex-wrap: wrap;
        }

            .product-line-item__action {
                order: 0;

                &:not(:last-child) {
                    margin-bottom: 1em;
                }
            }
}
