html {
    //Certain modals contain forms,
    //which are known to cause scrolling issues on iOS devices
    &.cancel-scroll--force {
        overflow: hidden;
        height: 100%;
    }
}

body {
    .cancel-scroll &{
        overflow: hidden;
    }

    .cancel-scroll--force & {
        overflow: hidden;
        height: 100%;
    }
}

.window-modal {
    z-index: 10000; //Nothing should be higher than this.
    overflow: auto;
    display: flex;
    // justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    -webkit-overflow-scrolling: touch;
    transition-property: opacity, visibility;

    &.modal-active {
        transition-timing-function: $transition-ease-out;
        transition-duration: $transition-speed-fast;
    }

    &:not(.modal-active) {
        pointer-events: none;
        transition-timing-function: $transition-ease-in;
        transition-duration: $transition-speed-fast;
        @include set-invisible();
    }

    &.modal-in-background {
        z-index: 9999;
        // background-color: transparentize($color-black, 0.8);
        pointer-events: none;
    }

    &.modal--opaque-background {
        background-color: transparentize($color-black, 0.7);
    }
}

    .window-modal__content {
        // z-index: 1;
        position: relative;
        width: 100%;
        min-height: 6.25rem;
        background-color: $color-white;
        transition-property: top, bottom, right, left, height, transform, opacity, visibility;
        transition-timing-function: $transition-ease-in;
        transition-duration: $transition-speed-fast;

        .modal-active & {
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-out;
        }

        .modal-in-background & > * {
            opacity: .5;
        }

        > .modal-container--static {
            display: block;
        }
    }

    .window-modal__close {
        z-index: 10;

        &:hover {
            opacity: .5;
        }

        &:after {
            content: url(get-icon(close, $color-body-copy));
            display: inline-block;
        }
    }

/*------------------------------------*\
  #UTILITY-MODAL-CLASSES
\*------------------------------------*/
.modal-container--static {
    display: none;
}

.modal--full {
    .window-modal__content {
        max-width: none;
    }
}

.modal--flex {
    .window-modal__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

.modal--hideable {
    &.modal-in-background {
        @include set-invisible();
    }
}

/*------------------------------------*\
  #GENERIC-MODAL
  Use these if you don't know what else to use.
\*------------------------------------*/

.modal--generic__title {
    line-height: $font-line-height-centi;
}

.modal--generic__description {
    margin-top: .75em;
    line-height: $font-line-height-deka;
}

.modal--generic__content {
    margin-top: 2rem;
}

.modal--generic__actions {
    margin-top: auto;
}

    .modal--generic__action {
        margin-top: 2.5rem;
    }

.modal--generic__scrollable {
    max-height: 17.5rem;
    padding-bottom: 1.25rem;
}

/*------------------------------------*\
  #SPECIAL-CASES
\*------------------------------------*/

.modal--quickview {
    .window-modal__content {
        max-width: 60rem;
        @include media-query(small-up) {
            // max-height: 95%;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .slick-initialized .slick-slide {
            height: auto;
        }
    }
    .quickview {
        padding: 3rem;
    }
    .product-quickview {
        padding-top: 1rem;
        padding-bottom: 1rem;
        .set-item {
            margin-bottom: 1rem;
        }
        .product-name {
            margin-bottom: 1rem;
            border-bottom: 1px solid #333;
            padding-bottom: 8px;
        }
        .product-availability__item {
            margin-bottom: 0.5rem;
        }
    }
    &.window-modal {
        background-color: rgba(0, 0, 0, 0.25);
    }
    .modal-footer {
        margin: 1rem 0;
        .cart-and-ipay {
            margin-top: 1rem;
        }
    }
}

.modal--store-locator {
    .window-modal__content {
        max-width: 38rem;
        padding: 2rem;
    }
}

.modal--subscription {
    .window-modal__content {
        max-width: 58rem;
    }
}

@include media-query(small-up) {
    body {
        .cancel-scroll--sm-up & {
            overflow: hidden;
        }
    }

    .window-modal__content {
        width: calc(100% - #{$gutter-outer-kilo});
        margin: auto;
        transform: scale(.9);
        @include set-invisible();

        .modal-active & {
            transition-delay: $transition-speed-faster;
            transform: scale(1);
            @include set-visible();
        }

        .modal-in-background & {
            transform: scale(.9) translateY(2rem);
        }
    }

    .window-modal__close {
        position: fixed;
        top: 1rem;
        right: 1rem;
        height: 3rem;
        width: 3rem;

        &:after {
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    /*------------------------------------*\
      #GENERIC-MODAL
      Use these if you don't know what else to use.
    \*------------------------------------*/

    .modal--generic {
        .window-modal__content {
            max-width: 29rem;
            min-height: 21.25rem;
            padding: 2.5rem;
        }
    }

    .modal--prompt {
        .window-modal__content {
            max-width: 26rem;
            min-height: 16rem;
            padding: 2rem;
        }
    }

    .modal--product-zoom {
        .window-modal__content {
            transform: unset;
        }
    }

    .modal--auth {
        padding: 2rem;
    }

    .modal--store-availability {
        .window-modal__content {
            padding: 0;
        }
    }
}

@include media-query(small) {
    html {
        &.cancel-scroll {
            overflow: hidden;
            height: 100%;
        }
    }

    .window-modal__content {
        top: 100%;
        margin-top: auto;

        .modal-active & {
            top: 0;
        }

        .modal-in-background & {
            transform: translateY(55%);
        }
    }

    .window-modal__close {
        position: absolute;
        top: 0;
        right: 0;
        height: 3rem;
        width: 2.5rem;

        &:after {
            width: 1rem;
            height: 1rem;
        }
    }

    /*------------------------------------*\
      #GENERIC-MODAL
      Use these if you don't know what else to use.
    \*------------------------------------*/

    .modal--generic {
        .window-modal__content {
            min-height: 20rem;
            padding-top: 2.5rem;
            padding-right: 1.25rem;
            padding-left: 1.25rem;
            padding-bottom: 3.75rem;
        }
    }

    .modal--prompt {
        .window-modal__content {
            min-height: 16rem;
            padding-top: 2rem;
            padding-right: 1.25rem;
            padding-left: 1.25rem;
            padding-bottom: 3.75rem;
        }
    }

    .modal--product-zoom {
        .window-modal__content {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    /*------------------------------------*\
      #UTILITY-MODAL-CLASSES
    \*------------------------------------*/

    .modal--small-full {
        background-color: $color-white;

        .window-modal__content {
            min-height: 100%;
            padding-bottom: 3.75rem;
        }
    }
}
