/*------------------------------------*\
  #ADDITIONAL RESETS
\*------------------------------------*/
html {
    font-size: 1rem; //This should always be 1rem to avoid weird calculations.
    font-family: $font-family-sans;
    line-height: $font-line-height-base;
    scroll-behavior: smooth;

    -ms-overflow-style: -ms-autohiding-scrollbar;
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
}

body {
    min-width: 320px; //Intentional px value to avoid custom font-sizes to override the actual min-width of the site.
    overflow-anchor: none;
    font-size: $font-size-base;
    font-weight: $font-weight-light;
    color: $color-body-copy;
    background-color: $color-body-background;
}

h1 {
    margin: 0;
}

iframe {
    border: none;
}

img,
video {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

legend {
    display: block;
    width: 100%; //Legends don't grow, they're born (with width issues).
}

address {
    font-style: normal;
}

ul, ol {
    padding-left: 1.25em;
}

hr {
    border: solid .0625rem;
}

//Sets a standard focus styles for elements across the site.
*:focus {
    outline-color: darken($color-accent-tertiary, 23%);
    outline-offset: .25rem;
    outline-style: solid;
    outline-width: .0625rem;

    .set--hide-click-focus & {
        outline: none;
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.list--reset {
    padding-left: 0;

    li {
        list-style-type: none;
    }
}

.set--w-100 {
    width: 100%;
}

.set--w-75 {
    width: 75%;
}

.set--w-50 {
    width: 50%;
}

.set--w-25 {
    width: 25%;
}

.set--h-100 {
    height: 100%;
}

.set--h-75 {
    height: 75%;
}

.set--h-50 {
    height: 50%;
}

.set--h-25 {
    height: 25%;
}

.scrollable {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    transform: translate3d(0,0,0);
}

/*------------------------------------*\
  Lazy Loading Transitions
\*------------------------------------*/

.blur-up {
    transition: filter $transition-ease-out $transition-speed-faster;

    &.lazyload,
    &.lazyloading {
        filter: blur(8px);
    }

    &.lazyloaded {
        filter: blur(0);
    }
}

.opacity-up {
    transition: opacity $transition-ease-out $transition-speed-fast;

    &.lazyload,
    &.lazyloading {
        opacity: 0;
    }

    &.lazyloaded {
        opacity: 1;
    }
}

.partial-opacity-up {
    transition: opacity $transition-ease-out $transition-speed-fast;

    &.lazyload,
    &.lazyloading {
        opacity: .8;
    }

    &.lazyloaded {
        opacity: 1;
    }
}

//Special case to hide image while it's loading but show no transition when done.
//Safari has issues when transitioning to a default property, so we can't use 'opacity-up'.
.none-up {
    &.lazyload,
    &.lazyloading {
        opacity: 0;
    }
}

/*------------------------------------*\
  Object Fit helpers
\*------------------------------------*/

[class*="object-fit"] {
    width: 100%;
    height: 100%;
}

.object-fit--cover {
    object-fit: cover;

    //IE11< object-fit polyfill.
    font-family: 'object-fit: cover;';
}

@include media-query(small-up) {
    .scrollable--small-up {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@include media-query(small) {
    // #chat-widget-container {
    //     left: 0;
    // }
    .scrollable--small-up {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}


.photo-tile-image.image-fluid {
    display: inline-block;
}

.photo-tile-figure{
    &.image-align-left{
        text-align: left;
    }
    &.image-align-right{
        text-align: right;
    }
    &.image-align-center{
        text-align: center;
    }
}