.billboard {

}

.billboard__title {
    @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-hecto , $font-size-kilo);
}

@include media-query(small-up) {

    .billboard__content-wrap {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
}

@include media-query(small) {

    .billboard__content-wrap {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}