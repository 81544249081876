
.header__minicart {
    position: relative;

    &.set--has-items {
        .header__minicart-link--no-items {
            display: none;
        }
    }

    &:not(.set--has-items) {
        .header__minicart-link--has-items {
            display: none;
        }
    }
}

    .header__minicart-link {
        position: relative;
    }

        .header__minicart-qty {
            position: absolute;
            top: 0;
            left: 72%;
            min-width: 1rem;
            height: 1rem;
            padding-right: .25em;
            padding-left: .25em;
            background-color: $color-primary;
            border-radius: 1rem;
            color: $color-white;
            font-size: .5625rem; //Intentionally not using a variable.
            font-weight: $font-weight-bold;
        }

    .minicart__error {
        .form-invalid-feedback {
            margin-top: 1rem;
        }
    }

.minicart__line-items {
    border-bottom: solid .0625rem $color-grey-2;
}

.mini-cart-new-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    // overflow: auto;

    .mini-cart-new-wrapper-top {
        // @include media-query('medium') {
        //     height: calc(100% - 665px);
        // }

        // @include media-query('medium-up') {
        //     height: calc(100% - 582px);
        // }

        @include media-query('small-up') {
            height: calc(100% - 583px);
        }
    }

    @include media-query('small') {
       overflow: auto;
       -webkit-overflow-scrolling: touch;
    }
}

.minicart__line-items {
    @include media-query('small-up') {
        height: calc(100% - 71px);
    }

    @include media-query('small') {
        height: auto;
    }
}

.overlay-panel__footer {
    @include media-query('small-up') {
        height: 582px;
    }
}

.minicart__footer-totals {
    padding-bottom: 1rem;
}

.minicart__footer-promo {
    padding-top: .625rem;
    padding-bottom: .5rem;
}

.minicart__footer-message {
    margin-top: .2em;
}

.minicart__footer-actions {
    margin-top: 1rem;

    .cart__promo_minicart {
        margin-bottom: 1rem;

        .button--primary {
            background: $color-grey-8;
            border-color: $color-grey-8;
        }
    }

    .cart-coupon__remove-action {
        display: none;
    }

    .cart-coupon {
        margin-bottom: .2rem;
    }

    .cart-coupon:first-child {
        margin-top: .2rem;
    }
}

.minicart-recommendation {
    .module-container__title {
        font-size:$font-size-deka
    }

    .module-container__header {
        &:not(:last-child) {
            margin-bottom: 1.25rem;
        }
    }

    .product-grid__item {
        margin-bottom: 0;
    }

    .product-tile__body-section {
        p {
            font-size: 0.625rem;
            &.product-tile__inline-badge {
                font-size: 0.563rem;
            }

            &.product-tile__name {
                font-size: 0.625rem;
            }
        }
    }

    .product-promotions {
        li {
            font-size: 0.563rem;
        }
    }    

    .body-type {
        font-size: 0.625rem;
    }

    .product-tile__swatches,
    .product-tile__wishlist {
        display: none;
    }
}