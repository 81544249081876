
.product-attribute {
    flex: 1 1 100%;

    &:not(:first-child) {
        margin-top: 2.25rem;
    }
}

.product-attribute--color {
    display: flex;
    flex-direction: column-reverse;
}

.product-attribute--qty {
    flex-basis: 5rem;
    flex-shrink: 0;
    max-width: 5rem;
}

    .product-attribute__contents {
        &:not(.product-attribute__contents--color) {
            //Substract the anchor attribute's horizontal padding for proper alignment.
            margin-right: -.75rem;
            margin-left: -.75rem;
        }
    }

    .product-attribute__contents--color {
        padding-left: .25rem;
        margin-top: -.75rem;
        margin-bottom: -.75rem;
    }

    .product-attribute__swatch {
        margin-top: .75rem;
        margin-bottom: .75rem;

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    .product-attribute__selected-value {
        margin-bottom: .6rem;
    }

    .product-attribute__anchor {
        padding: .25rem .75rem;
        margin-right: 0.5rem;

        &:hover {
            .product-attribute__anchor-label {
                //Prevents text flicker when hovering.
                -webkit-text-stroke-width: .0625rem;
            }
        }

        &.selected {
            .product-attribute__anchor-label {
                border-bottom-color: inherit;
                -webkit-text-stroke-width: initial;
                font-weight: $font-weight-semibold;
            }
        }

        &.unselectable {
            text-decoration: line-through;
            opacity: 0.2;
        }
    }

        .product-attribute__anchor-label {
            padding-bottom: .2em;
            border-bottom: solid .125rem transparent;
            transition: border-bottom $transition-speed-fast $transition-ease-in;
        }

    .size-chart {
        margin-top: .5rem;
    }

    .product-availability {
        margin-top: 1.25rem;
        
        &:not(:first-child) {
            margin-left: 1rem;
        }
    }

        .product-availability__label {
            margin-right: .625em;
        }

.product-add__container {
    position: relative;
}

.product-badge__list {
    margin-bottom: 1rem;
}

    .product-badge__item {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

.product-wishlist__trigger {
    &.set--in-wishlist {
        .product-wishlist__add {
            display: none;
        }

        .product-wishlist__remove {
            display: block;
        }
    }
}

    .product-wishlist__remove {
        display: none;
    }

.product-actions__additional {
    margin-top: 1rem;
}

    .product-actions__item {
        position: relative;
    }

.product-common__social-item {
    &:not(:last-child) {
        margin-right: 1.0375rem;
    }
}

.product-attribute__head--underline {
    border-bottom: solid .0625rem $color-grey-2;
    margin-bottom: 1rem;
    padding-bottom: .25rem;
}

/*------------------------------------*\
  #STORE-AVAILABILITY
\*------------------------------------*/

.product-store-availability__item {
    &:not(:last-child) {
        margin-bottom: 1.25em;
    }
}

@include media-query(medium) {
    .product-attribute__anchor {
        &:hover {
            .product-attribute__anchor-label {
                -webkit-text-stroke-width: 0;
            }
        }
    }
}

.affirm-modal-trigger {
    border-bottom: 1px solid #333;
    transition: all 0.2s ease-in;
    &:hover {
        border-bottom-color: transparent;
    }
}

.button.affirm-place-order {
    text-transform: capitalize;
    padding: 10px 0 25px;
    font-size: 1rem;
    .affirm-payment-option {
        width: auto;
        margin: 0;
        display: inline-block;
        position: relative;
        left: -13px;
        vertical-align: bottom;
        top: -3px;
    }
}

.affirm-as-low-as {
    &.hide {
        display: none;
    }
}

.quick-view__arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}