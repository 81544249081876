.swatch--color {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: inset 0 0 0 1px transparentize($color-black, .875);
    // background-size: cover;

    &:after {
        position: absolute;
        top: -.25rem;
        left: -.25rem;
        right: -.25rem;
        bottom: -.25rem;
        border: solid .125rem $color-black;
        opacity: 0;

        transition-property: opacity;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-bezier;

        content: '';
    }

    &:hover, &.selected {
        &:after {
            opacity: 1;
        }
    }

    @include property-modifiers($colors, background-color, '-');

    &.disabled, &.unselectable {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.unselectable {
        display: none;
    }
}

.swatch__icon--color {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: inherit;
    height: inherit;
}

.swatch--color-small {
    width: 1.5rem;
    height: 1.125rem;
}

.swatch--color-large {
    width: 2rem;
    height: 1.5rem;
}
