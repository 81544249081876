
.breadcrumbs {
    margin-bottom: 1rem;
}

.breadcrumbs--spaced {
    margin-top: 1rem;
}

    .breadcrumbs__item {
        &:not(:last-child) {
            margin-right: 0.5em;
            
            @include media-breakpoint-down(sm) {
                max-width: 30%;
            }
            
        }
    }
    .breadcrumbs__anchor {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .breadcrumbs__separator {
        display: inline-block;
        margin-left: 0.5em;
    }