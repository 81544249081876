.price {
    .strike-through {
        opacity: .6;
    }

    .price__original {
        margin-right: .5em;
    }

    .starting,
    .range,
    .sales {
        // font-weight: $font-weight-semibold;
    }

    .tiered {
        table {
            border-top: solid .0625rem $color-grey-3;
            margin: 0 auto;

            tr {
                &:nth-child(odd) {
                    background-color: $color-grey-2;
                }
            }
        }

        td,
        span.price {
            font-size: 0.875rem;
            font-weight: $font-weight-semibold;
        }

        td {
            padding: 0.313rem;

            &.quantity {
                font-weight: normal;
                text-align: right;
            }

            &.value {
                text-align: left;
            }
        }

        .table-header {
            font-size: 1.125rem;
            padding: 0.313rem;
        }

        .column-header {
            font-size: 1rem;
            padding: 0.313rem;
            font-weight: normal;
        }

        .column-header.quantity {
            text-align: right;
        }
    }
}
