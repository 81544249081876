
.content-tile {
    position: relative;

    &:hover {
        .content-tile__title,
        .content-tile__description {
            opacity: .75;
        }
    }
}

.content-tile--ratio-inherit {
    flex-grow: 1;
}

    .content-tile__aspect-ratio--inherit {
        min-height: 100%;
        flex-grow: 1;

        @include aspect-ratio(100, 75, true);
    }

.content-tile--style-default {
    &.content-tile--size-large {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h5, $font-size-h4);
        }
    }

    &.content-tile--size-regular {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h6, $font-size-h5);
        }
    }

    &.content-tile--size-small {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-deka, $font-size-kilo);
        }
    }
}

.content-tile--style-feature {
    &.content-tile--size-large {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h5, $font-size-h1);
        }
    }

    &.content-tile--size-regular {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h5, $font-size-h3);
        }
    }

    &.content-tile--size-small {
        .content-tile__title {
            @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h6, $font-size-h5);
        }
    }
}

    .content-tile__aspect-ratio--square {
        @include aspect-ratio(360, 375, true);
    }

    .content-tile__aspect-ratio--wide {
        @include aspect-ratio(1103, 613, true);
    }

    .content-tile__aspect-ratio--tall {
        @include aspect-ratio(5, 7, true);
    }

    .content-tile__title,
    .content-tile__description {
        transition: opacity $transition-speed-faster $transition-ease-default;

        a {
            text-decoration: underline;
        }
    }

    .content-tile__actions {
        margin-right: -.25rem;
        margin-left: -.25rem;
    }

        .content-tile__cta {
            margin-right: .25rem;
            margin-left: .25rem;

            &:not(:last-child) {
                margin-bottom: .75rem
            }
        }

@include media-query(medium-up) {
    .content-tile--style-feature {
        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-right: 2.75rem;
                padding-left: 2.75rem;
            }
        }
    }
}

@include media-query(small-up-to-medium) {
    .content-tile--style-feature {
        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-right: 2rem;
                padding-left: 2rem;
            }
        }
    }
}

@include media-query(small-up) {
    .content-tile--style-default {
        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-right: 2rem;
                padding-left: 2rem;
            }
        }

        .content-tile__description {
            margin-top: 1em;
        }

        .content-tile__actions:not(:first-child) {
            margin-top: .75rem;
        }
    }

    .content-tile--style-feature {
        .content-tile__description {
            margin-top: .8125rem;
        }

        .content-tile__actions:not(:first-child) {
            margin-top: 2rem;
        }
    }

    .content-tile--size-large {
        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
            }

            &.set--text-after,
            &.set--text-after-large {
                padding-top: 1.5rem;
            }

            &.set--text-before,
            &.set--text-before-large {
                padding-bottom: 1.5rem;
            }
        }
    }

    .content-tile--size-regular,
    .content-tile--size-small {
        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 2.5rem;
                padding-bottom: 2.5rem;
            }

            &.set--text-after,
            &.set--text-after-large {
                padding-top: .75rem;
            }

            &.set--text-before,
            &.set--text-before-large {
                padding-bottom: .75rem;
            }
        }
    }
}

@include media-query(small) {
    .content-tile__content-wrap {
        &.set--text-overlay,
        &.set--text-overlay-small {
            padding: 1.25rem 1rem;
        }

        &.set--text-after,
        &.set--text-after-small {
            padding-top: 1rem;
        }

        &.set--text-before,
        &.set--text-before-small {
            padding-bottom: 1rem;
        }
    }

    .content-tile__description {
        margin-top: .35rem;
    }

    .content-tile__actions:not(:first-child) {
        margin-top: .75rem;
    }
}

.content-tile {
    &.set--video-ready {
        .content-tile__aspect-ratio {
            z-index: 2;
        }
        .component-overlay--low-index {
            z-index: -1;
        }
        .video-js {
            &.center {
                .vjs-play-toggle {
                    height: 150px;
                    width: 150px;
                    font-size: 100px;
                    top: 50%;
                    bottom: auto;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    .vjs-icon-placeholder:before {
                        font-size: 150px;
                        opacity: 0.6;
                        line-height: inherit;
                    }
                }
            }
            &.bottom {
                .vjs-play-toggle {
                    left: 1px;
                    bottom: 0;
                }
            }
            &.vjs-user-inactive {
                &.center {
                    .vjs-play-toggle {
                        visibility: hidden;
                        opacity: 0;
                        transition: visibility 1s, opacity 1s;
                    }
                }
            }
        }
    }
    &.set--video-playing {
        .video-js{
            &.center {
                .vjs-play-toggle {
                    &.vjs-playing {
                        .vjs-icon-placeholder:before {
                            opacity: 0.4;
                        }
                    }
                }
            }
        }
    }
}

@include media-query(small) {
    .content-tile {
        &.set--video-ready {
            .video-js {
                &.center {
                    .vjs-play-toggle {
                        height: 100px;
                        width: 100px;
                        .vjs-icon-placeholder:before {
                            font-size: 100px;
                        }
                    }
                }
            }
        }
    }
}