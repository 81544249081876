@import "videojs-theme";

.vjs-hidden {
    display: none !important;
}

.vjs-tech {
    width: 100%;
    height: 100%;
    object-fit: inherit;
    pointer-events: none;
}

.vjs-poster,
.vjs-text-track-display {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
}

.vjs-poster {
    bottom: 0;
    background-size: cover;
    background-position: center;

    .vjs-has-started & {
        @include set-invisible();
    }
}

.vjs-text-track-display {
    bottom: 3em;
}

.vjs-loading-spinner {
    @include set-invisible();
}

.vjs-big-play-button {
    z-index: 1;
}

.video-js {
    // &.vjs-user-inactive:not(.vjs-paused) {
    //     .vjs-play-toggle {
    //         opacity: 0;
    //         pointer-events: none;
    //     }
    // }

    .vjs-play-toggle {
        z-index: 3;
        position: absolute;
        top: auto;
        bottom: 20px;
        left: 20px;
        margin: auto;
        height: 40px;
        width: 40px;
        // background-color: transparentize($color-black, 0.5);
        border-radius: 50%;
        line-height: 38px;
        font-size: 24px;
        // border: 2px solid #fff;

        transition: opacity $transition-speed-fast $transition-ease-out;

        .vjs-icon-placeholder:before {
            font-size: 1em;
            line-height: inherit;
        }
    }
}
