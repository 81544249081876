
.link, .link--primary {
    transition: opacity $transition-speed-faster $transition-ease-default;

    &:hover {
        opacity: .7;
    }
}

.link--primary {
    align-items: center;
}

.link--primary,
.link--secondary,
.link--tertiary {
    display: inline-flex;
    position: relative;
    font-family: $font-family-sans-secondary;
    font-weight: $font-weight-semibold;
    font-size: $font-size-base;
    text-transform: uppercase;
    letter-spacing: .0666667em;
}

.link--primary,
.link--secondary {
    &:before {
        position: absolute;
        top: calc(100% + .125em);
        height: .0625rem;
        left: auto;
        right: 0;
        width: 0;
        background-color: currentColor;
        transition: width $transition-speed-fast $transition-ease-in;
        content: '';
    }

    &:hover, &:focus {
        &:before {
            left: 0;
            right: auto;
            width: 100%;
            transition-timing-function: $transition-ease-out;
        }
    }
}

.link--underline {
    text-decoration: underline;
}

.link--underline-hover {
    &:hover, &:focus {
        text-decoration: underline;
    }
}

.link--highlight-hover {
    &:hover, &:focus {
        color: $color-primary;
    }
}

/**
 *  Utility classes for buttons and pseudo buttons
 */
.cursor--pointer:hover {
    cursor: pointer;
}

.cursor--not-allowed:hover {
    cursor: not-allowed;
}
