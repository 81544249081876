
.hero,
.hero__aspect-ratio {
    //Ensure relative position on viewports that might not use `aspect-ratio`.
    position: relative;
}

.hero--size-large {
    .hero__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h3, $font-size-h1);
    }
}

.hero--size-regular {
    .hero__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h6, $font-size-h4);
    }
}

.hero--size-small {
    .hero__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-kilo, $font-size-h5);
    }
}

.hero__subtitle {
    margin-bottom: .5em;
}

.hero__actions {
    margin-right: -.5rem;
    margin-left: -.5rem;
}

.hero__cta {
    margin-right: .5rem;
    margin-left: .5rem;

    &:not(:last-child) {
        margin-bottom: 1.25rem
    }
}

@include media-query(large-up) {
    //Hacky way to force Slick to respect the Hero's height when in a carousel.
    //This is necessary since Slick adds height: 100%; and min-height: 1px; to slide items.
    .hero__aspect-ratio {
        &:before {
            display: block;
            content: '';
        }
    }

    .hero__aspect-ratio--slim {
        &:before {
            height: 31.25rem;
        }
    }

    .hero__aspect-ratio--hairline {
        &:before {
            height: 18.75rem;
        }
    }

    .hero--main {
        .hero__content-wrap.bindPosition {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 11rem;
                padding-bottom: 11rem;
            }

            &.set--text-after,
            &.set--text-after-large {
                padding-top: 3.3rem;
            }

            &.set--text-before,
            &.set--text-before-large {
                padding-bottom: 3.3rem;
            }
            &.component-v-align--bottom {
                padding-bottom: 3rem;
            }
            &.component-v-align--top {
                padding-top: 3rem;
            }
        }
    }
}

@include media-query(xmedium-up-to-large) {
    .hero--main .hero__content-wrap.bindPosition {
        &.component-v-align--bottom {
            padding-bottom: 3rem;
        }
        &.component-v-align--top {
            padding-top: 3rem;
        }
    }
}
@include media-query(small-up-to-large) {
    .hero__aspect-ratio--slim {
        @include aspect-ratio(1440, 500, true);
    }

    .hero__aspect-ratio--hairline {
        @include aspect-ratio(1440, 300, true);
    }
}
@include media-query(small-up-to-xmedium) {
    .hero--main {
        .hero__content-wrap.bindPositionTablet {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 18vh;
                padding-bottom: 18vh;
            }

            &.set--text-after,
            &.set--text-after-large {
                padding-top: 5.4vh;
            }

            &.set--text-before,
            &.set--text-before-large {
                padding-bottom: 5.4vh;
            }
            &.component-v-align--bottom {
                padding-bottom: 8vh;
            }
            &.component-v-align--top {
                padding-top: 8vh;
            }
        }
    }
}

.hero--main .hero__content-wrap{
    .hero__content{
        padding: 4vh 10vh;
        @include media-query(small-up){
            &.d-bg-white {
                background-color: white;
                color: black;
                border: black 0.07rem solid;
            }
            &.d-bg-black {
                background-color: black;
                color: white;
                border: white 0.07rem solid;
            }
        }
        @include media-query(small){
            &.m-bg-white {
                background-color: white;
                color: black;
                border: black 0.07rem solid;
            }
            &.m-bg-black {
                background-color: black;
                color: white;
                border: white 0.07rem solid;
            }
        }
    }
    .component-actions__cta{
        @include media-query(small-up){
            &.mobile{
                display: none;
            }
        }
        @include media-query(small){
            &.desktop{
                display: none;
            }
            &.button {
                padding: 1em 2.1em;
            }
        }
    }
}

@include media-query(small){
    .hero--main .hero__content-wrap .hero__content{
        padding: 2vh 7vh;
    }
}

@include media-query(small-up) {
    .hero__aspect-ratio--main {
        &:before {
            display: block;
            //Substract the desired header size from the viewport height.
            height: calc(100vh - 3.9375rem);
            min-height: 27.5rem;
            content: '';
        }

        //.set--header-blend Is set in the ".page" element (pdStorePage.isml, page.isml, etc.).
        //This modifier class controls wether or not the header should be blend and transparent.
        //Whenever this class is present, the "viewport height" heroes should occupy the full viewport's height.
        .set--header-blend &:before {
            height: 100vh;
        }
    }

    .hero--slim,
    .hero--hairline {
        .hero__content-wrap {
            padding-top: 3.75rem;
            padding-bottom: 3.75rem;
        }
    }

    .hero__description {
        margin-top: .5em;
        max-width: 40rem;

        .text-align--center & {
            margin-left: auto;
            margin-right: auto;
        }

        .text-align--right & {
            margin-left: auto;
        }
    }
    .hero__subtitle, .hero__title, .hero__description {
        &.mobile {
            display: none;
        }
    }

    .hero__actions {
        .set--text-overlay &,
        .set--text-overlay-large & {
            margin-top: 3.5rem;
        }

        .set--text-after &,
        .set--text-after-large &,
        .set--text-before &,
        .set--text-before-large & {
            margin-top: 1.25rem;
        }
    }
}

@include media-query(small) {
    .hero__aspect-ratio--main {
        &:before {
            display: block;
            height: calc(100vh - 11rem);
            min-height: 16.25rem;
            content: '';
        }
    }

    .hero__aspect-ratio--slim,
    .hero__aspect-ratio--hairline {
        @include aspect-ratio(375, 160, true);
    }

        .hero__content-wrap.bindPositionMobile {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 5.5rem;
                padding-bottom: 1.25rem;
            }

            &.set--text-after,
            &.set--text-after-large {
                padding-top: 2rem;
            }

            &.set--text-before,
            &.set--text-before-large {
                padding-bottom: 2rem;
            }

            .hero-carousel &.set--text-after,
            .hero-carousel &.set--text-after-small {
                padding-bottom: 4rem;
            }
            &.component-v-align--bottom {
                padding-top: 5.5rem;
                padding-bottom: 1.25rem;
            }
            &.component-v-align--top {
                padding-top: 1.25rem;
                padding-bottom: 1.25rem;
            }
        }

        .hero__description {
            margin-top: .875em;
        }
        .hero__subtitle, .hero__title, .hero__description {
            &.desktop {
                display: none;
            }
        }
        .hero--size-large .hero__title{
            font-size: 2.75rem;
        }
        .hero__description {
            font-size: 0.875rem;
        }


        .hero__actions {
            .set--text-overlay &,
            .set--text-overlay-large & {
                margin-top: 1.5rem;
            }

            .set--text-after &,
            .set--text-after-large &,
            .set--text-before &,
            .set--text-before-large & {
                margin-top: 1rem;
            }
        }
}

.no-results {
    .hero--main .hero__content-wrap{
        .component-actions__cta{
            @include media-query(small-up){
                &.mobile{
                    display: inline-block;
                }
            }
            @include media-query(small){
                &.desktop{
                    display: block;
                }
            }
        }
    }

    @include media-query(small) {
        .hero__subtitle, .hero__title{
            &.desktop {
                display: inline-block;
            }
        }
    }

    @include media-query(small-up) {
        .hero__subtitle, .hero__title {
            &.mobile{
                display: inline-block;
            }
        }
    }

}