
.tab-nav {
    display: flex;
    position: relative;
    margin-bottom: 2rem;
    box-shadow: inset 0 -.0625rem 0 0 $color-grey-2;
}

.tab-nav--no-spacing {
    margin-bottom: 0;
}

    .tab-nav__item,
    .non-switchable-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: .5em;
        padding-bottom: .75em;
        text-align: center;
        font-weight: $font-weight-semibold;
        transition-property: box-shadow, color;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-bounce;
        cursor: pointer;

        &:hover:not(.toggle--active) {
            color: $color-grey-4;
        }

        //Only grow to use all available space when used within a .tab-nav container.
        .tab-nav & {
            flex: 1 1 100%;
        }

        &.toggle--active,
        .tab-nav__item-check.toggle--active + & {
            box-shadow: inset 0 -.25rem 0 0 $color-primary;
            transition: box-shadow $transition-speed-normal $transition-ease-default;
        }
    }

    .tab-nav__item-check {
        position: absolute;
        opacity: 0;
    }

    .tab-nav__item-icon {
        max-height: 1.5rem;
    }

.tab-content {
    position: relative;
}

    .tab-content__panel {
        // transition-property: opacity, visibility;
        // transition-duration: $transition-speed-fast;
        // transition-timing-function: $transition-ease-default;

        // &:not(.toggle--active) {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;

        //     @include set-invisible();
        // }

        // &.toggle--active {
        //     @include set-visible();
        // }

        &:not(.toggle--active) {
            display: none;
        }
    }
