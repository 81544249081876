/*------------------------------------*\
  #ICONS - Mapped to the static svg directory file names
\*------------------------------------*/

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;

    //In case the .icon class is not added directly on the svg element.
    svg {
        width: 100%;
        height: 100%;
        fill: inherit;
    }
}

.icon--small {
    width: 0.5rem;
    height: 0.5rem;
}

.icon--medium {
    width: 1.125rem;
    height: 1.125rem;
}

.icon--large {
    width: 2.5rem;
    height: 2.5rem;
}

.icon--rotate-h {
    //Mirror and not rotate. Rotation can cause a slightly off rendering.
    transform: scaleX(-1);
}

.icon--rotate-up {
    transform: rotate(90deg);
}

.icon--rotate-down {
    transform: rotate(-90deg);
}

.icon--set-left {
    margin-right: .85em;
}

.icon--set-left-short {
    margin-right: .5em;
}

.icon--set-right {
    margin-left: .85em;
}

.icon--set-right-short {
    margin-left: .5em;
}

.icon--inline-align-center {
    vertical-align: middle;
}