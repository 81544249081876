/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

button {
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    font: inherit;
}

.button {
    //For non-button tags:
    display: inline-block;
    padding: 1.125em 2.075em;
    border: solid .0625rem;
    border-radius: $input-border-radius;
    font-size: $font-size-deci;
    font-weight: $font-weight-semibold;
    font-family: $font-family-sans-secondary;
    letter-spacing: .0666667em;
    text-align: center;
    text-transform: uppercase;
    transition: opacity $transition-speed-normal $transition-ease-bezier,
                color $transition-speed-normal $transition-ease-bezier,
                border-color $transition-speed-normal $transition-ease-bezier,
                background-color $transition-speed-normal $transition-ease-bezier;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 0.65;
    }

    &[disabled], &.disabled {
        background-color: $color-grey-2;
        border-color: $color-grey-2;
        color: $color-grey-5;
        cursor: not-allowed;
    }
}

.button--small {
    padding: .825em 1.125em;
}

.button--large {
    padding: 1.5em 1.25em;
}

//Necessary override to avoid some difficult choices.
.button--flex {
    display: flex;
}

//Allows the contents of the button to define its width.
//Helpful when the button's default padding is too large.
.button--fluid {
    padding-right: 0;
    padding-left: 0;
}

.button--primary,.button--black {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-white;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-white;
        color: $color-primary;
    }
}

.button--primary-outline, .button--black-outline {
    color: $color-primary;
    border-color: $color-primary;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;
    }
}

.button--secondary {
    background-color: $color-secondary;
    border-color: $color-secondary;
    color: $color-white;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-white;
        color: $color-secondary;
    }
}

.button--secondary-outline {
    color: $color-secondary;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-secondary;
        border-color: $color-secondary;
        color: $color-white;
    }
}

.button--white {
    background-color: $color-white;
    border-color: $color-white;
    color: $color-primary;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-primary;
        color: $color-white;
    }
}

.button--white-outline {
    color: $color-white;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;
    }
}

// .button--tertiary {
//     background-color: $color-tertiary;
//     border-color: $color-tertiary;
//     color: $color-white;

//     &:hover:not([disabled]):not(.disabled) {
//         opacity: 1;
//         background-color: $color-white;
//         color: $color-tertiary;
//     }
// }

// .button--tertiary-outline {
//     color: $color-tertiary;

//     &:hover:not([disabled]):not(.disabled) {
//         opacity: 1;
//         background-color: $color-tertiary;
//         border-color: $color-tertiary;
//         color: $color-white;
//     }
// }

.button--cross-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5em;
    height: 2.5em;
    transition: opacity $transition-speed-normal $transition-ease-bezier;

    &:hover, &:focus {
        opacity: .5;
    }
}

.minicart__footer-actions {
    .edit-btn {
        display: inline-block;
        margin-top: 20px;
    }
}

/*------------------------------------*\
  #CHIPS
\*------------------------------------*/

.chip {
    display: inline-block;
    min-width: 3em;
    padding: .3em 0.5em;
    border: solid .0625rem $color-grey-4;
    border-radius: $input-border-radius;
    text-align: center;
    font-weight: $font-weight-bold;

    &:hover {
        border-color: $color-grey-7;
    }

    &.selected {
        border-color: $color-primary;
        background-color: $color-primary;
        color: $color-white;
    }

    &[disabled], &.disabled {
        background-color: $color-grey-2;
        border-color: $color-grey-2;
        color: $color-grey-5;
        cursor: not-allowed;
    }
}

/*------------------------------------*\
  #PILLS
\*------------------------------------*/

.pill {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 2.5em;
    padding: .4em .85em;
    border: solid .0625rem;
    font-size: $font-size-centi;
    font-weight: $font-weight-semibold;
    font-family: $font-family-sans-secondary;
    letter-spacing: .0666667em;
    text-transform: uppercase;

    &:hover {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;
    }
}

.pill--icon-left {
    padding-left: .35em;
}

.pill--icon-right {
    padding-right: .5em;
}

    .pill__icon--actionable {
        &:hover {
            opacity: .5;
        }
    }

    .pill__icon--swatch {
        border: solid .0625rem $color-white;
    }

    .pill__icon--left {
        margin-right: .5em;
    }

    .pill__icon--right {
        margin-left: 1em;
    }

@media (-ms-high-contrast: active) {
    .chip {
        position: relative;

        &.selected {
            &:after {
                position: absolute;
                top: -.25rem;
                left: -.25rem;
                right: -.25rem;
                bottom: -.25rem;
                border: solid .125rem;

                content: '';
            }
        }

        &[disabled], &.disabled {
            opacity: .5;
        }
    }
}
