
.footer--checkout {
    border-top: solid .0625rem $color-grey-2;
}

.footer__heading {
    margin-bottom: 1.25em;
}

.footer-copyright__list {
    margin: -1rem;
}

    .footer-copyright__item {
        margin: 1rem;
    }

@include media-query(small-up) {
    .footer--checkout {
        padding-top: 2.25rem;
        padding-bottom: 2.5rem;
    }

    .footer__main-content {
        margin-bottom: 3rem;
    }

    .footer-copyright {
        margin-top: 2.5rem;
        margin-bottom: 2.875rem;
    }
}

@include media-query(small) {
    .footer {
        padding-bottom: 10rem;
    }

    .footer--checkout {
        padding-top: 2.25rem;
    }

        .footer-copyright {
            margin-top: 3rem;
        }
}