@import "~base/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/variables";

.checkout-and-applepay .btn {
    padding: 1em 1em;
}

.dw-apple-pay-button.apple-pay-pdp,
.dw-apple-pay-button.apple-pay-pdp:hover,
.dw-apple-pay-button.apple-pay-cart,
.dw-apple-pay-button.apple-pay-cart:hover {
    padding: 1em 1em;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    border-radius: $border-radius;
}

.dw-apple-pay-button.btn {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 0;
    background-color: #14100B;
    border-color: #14100B;
    margin-top: 10px;
    &:hover {
        background-color: #14100B !important;
        border-color: #14100B !important;
        margin-top: 10px;
    }
}

isapplepay.btn {
    display: none !important;
}

.add-to-cart,
.add-to-cart-global {
    flex-basis: 0;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-down(xs) {
        max-width: 100%;
    }
}

.cart-and-ipay {
    justify-content: center;

    .pdp-checkout-button {
        padding: 0;

        .add-to-cart {
            padding: 0.5em 1em;
        }

        .add-to-cart-global {
            padding: 0.5em 1em;
        }
    }

    .pdp-apple-pay-button {
        padding: 0;
    }
}

.applepay-tab-wrapper {
    display: none;
}

body.apple-pay-enabled .applepay-tab-wrapper {
    display: block;
}
