.page,
.header {
    &:not(.set--overlay):after {
        @include set-invisible();
    }
}

.header {
    &:after {
        position: absolute;
    }
}

.page {
    //When an overlay is applied to the .page, set it as fixed so that it
    //always fills the entire screen.
    &:after {
        position: fixed;
    }
}

.loader-container {
    position: relative;
}

/*------------------------------------*\
  #OVERLAYS
\*------------------------------------*/


.set--overlay {
    position: relative;
    //Helps iOS detect taps on overlaid pseudo-element.
    cursor: pointer;
    z-index: 5;
}

//Forces overlay on top of everything.
//Limit to 20, so that other components can "punch through".
.set--overlay-all {
    &:after {
        z-index: 1080;
    }
}

.loader,
.set--overlay:after {
    position: absolute;
}

.loader,
.set--overlay:after,
.page:not(.set--overlay):after,
.header:not(.set--overlay):after {
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.set--overlay:after,
.page:not(.set--overlay):after,
.header:not(.set--overlay):after {
    background-color: transparentize($color-grey-7, 0.7);
    transition: opacity $transition-speed-fast $transition-ease-default,
                visibility $transition-speed-fast $transition-ease-default;
}

.set--overlay,
.page:not(.set--overlay),
.header:not(.set--overlay) {
    &:after {
        content: '';
    }
}

.loader {
    z-index: 10000; //It's over 9000!. This should overlay *everything*.
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition-property: opacity, visibility;
    transition-timing-function: $transition-ease-in;
    transition-duration: $transition-speed-fast;

    @include set-invisible();

    &.set--alt {
        background-color: transparentize($color-grey-7, 0.7);

        .loader-indicator {
            background-image: url("../images/loading-white.svg");
        }
    }

    &:not(.set--alt) {
        background-color: transparentize($color-white, 0.4);

        .loader-indicator {
            background-image: url("../images/loading.svg");
        }
    }

    &.set--fixed {
        position: fixed;
    }

    &.set--visible {
        transition-timing-function: $transition-ease-out;
        transition-duration: $transition-speed-medium;
        @include set-visible();
    }
}

    .loader-indicator {
        z-index: 1;
        width: 100%;
        height: 100%;
        max-width: 8rem;
        max-height: 8rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .loader-message {
        max-width: 20.625rem;
        padding: 0 1rem;
        text-align: center;
        font-weight: $font-weight-bold;
        color: $color-secondary;
        background-color: transparentize($color-white, .25);
    }
