
.descriptive-card {
    border: solid .0625rem $color-grey-2;
}

.descriptive-card--size-large {
    .descriptive-card__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h5, $font-size-h2);
    }
}

.descriptive-card--size-regular {
    .descriptive-card__title {
        font-size: $font-size-h6;
    }
}

.descriptive-card--size-small {
    .descriptive-card__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h6, $font-size-h5);
    }
}

    .descriptive-card__actions {
        margin-top: 1.25rem;
        margin-right: -.75rem;
        margin-left: -.75rem;
    }

        .descriptive-card__cta {
            margin-right: .75rem;
            margin-left: .75rem;

            &:not(:last-child) {
                margin-bottom: 1.5rem
            }
        }

@include media-query(small-up) {
    .descriptive-card {
        &:not(:last-child) {
            margin-bottom: 5.25rem;
        }
    }

        .descriptive-card__media {
            @include aspect-ratio(774, 474, true);
        }

        .descriptive-card__background {
            padding-top: 1.8rem;
            padding-bottom: 2.25rem;
        }

        .descriptive-card__description {
            margin-top: 1em;
        }
}

@include media-query(small) {
    .descriptive-card {
        &:not(:last-child) {
            margin-bottom: 1.875rem;
        }
    }

        .descriptive-card__media {
            @include aspect-ratio(320, 200, true);
        }

        .descriptive-card__background {
            padding-top: 2rem;
            padding-bottom: 2.5rem;
        }

        .descriptive-card__description {
            margin-top: 1.25em;
        }
}
