
.footer-links__list--unstacked {
    display: flex;
    justify-content: center;
    align-items: center;
}

@include media-query(small-up) {
    .footer-links {
        &:not(:last-child) {
            margin-bottom: 2.75rem;
        }
    }

        .footer-links__list--stacked {
            .footer-links__item {
                &:not(:last-child) {
                    margin-bottom: .7em;
                }
            }
        }

        .footer-links__list--unstacked {
            .footer-links__item {
                &:not(:last-child) {
                    margin-right: 2.5em;
                }
            }
        }
}

@include media-query(small) {
    .footer-links,
    .footer__info-group {
        &:not(:last-child) {
            margin-bottom: 3.5rem;
        }
    }

        .footer-links__list--stacked {
            .footer-links__item {
                &:not(:last-child) {
                    margin-bottom: 1.25em;
                }
            }
        }

        .footer-links__list--unstacked {
            .footer-links__item {
                &:not(:last-child) {
                    margin-right: 2.25em;
                }
            }
        }
}
