/*------------------------------------*\
  #MODULE COMPONENTS
\*------------------------------------*/
.component-overlay-container {
    position: relative;
    max-width: 100%;
}

.component-overlay {
    position: absolute;
    overflow: hidden;
}

.component-overlay--start {
    top: 0;
    left: 0;
}

.component-overlay--center {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.component-overlay--low-index {
    z-index: 1;
}

/*------------------------------------*\
  #MODULE ALIGNMENT
\*------------------------------------*/

.component-v-align--top {
    align-items: flex-start;
}

.component-v-align--center {
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
}

.component-v-align--bottom {
    align-items: flex-end;
    margin-top: auto;
}

.component-h-align--left {
    justify-content: flex-start;
}

.component-h-align--center {
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
}

.component-h-align--right {
    justify-content: flex-end;
    margin-left: auto;
}

/*------------------------------------*\
  #MODULE IMAGES
\*------------------------------------*/
.component-image {
    object-position: var(--focal-point-x) var(--focal-point-y);
}

/*------------------------------------*\
  #MODULE CTAs
\*------------------------------------*/
.component-actions {
    //Ensures actions are accessible when an CTA overlay is used.
    z-index: 1;
    position: relative;
}

//Workaround for extra wrapper elements that are rendered by Page Designer.
.component-actions--inner {
    display: inline;
}

    .component-actions__cta--fake {
        cursor: pointer;
    }

/*------------------------------------*\
  #MODULE CONTAINERS
\*------------------------------------*/
.module-container {
    flex-grow: 1; //Fixes IE11 shrink issues.
}

    .module-container__description {
        margin-top: .5em;
    }

/*------------------------------------*\
  #MODULE GRID
\*------------------------------------*/
.module-grid {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
}

    .module-grid__item {
        margin-top: .75rem;
        margin-bottom: .75rem;
    }

@include media-query(medium-up) {
    /*------------------------------------*\
      #MODULE CONTAINERS
    \*------------------------------------*/
    .module-container--spacing-top-large {
        padding-top: 4.5rem;
    }

    .module-container--spacing-bottom-large {
        padding-bottom: 4.5rem;
    }
    .module-container--spacing-left-large {
        padding-left: 4.5rem;
    }
    .module-container--spacing-right-large {
        padding-right: 4.5rem;
    }
    .module-container--spacing-left-large {
        padding-left: 4.5rem;
    }
    .module-container--spacing-right-large {
        padding-right: 4.5rem;
    }

    .module-container--spacing-top-regular {
        padding-top: 2.5rem;
    }

    .module-container--spacing-bottom-regular {
        padding-bottom: 2.5rem;
    }
    .module-container--spacing-left-regular {
        padding-left: 2.5rem;
    }
    .module-container--spacing-right-regular {
        padding-right: 2.5rem;
    }

    .module-container--spacing-top-small {
        padding-top: 1.5rem;
    }

    .module-container--spacing-bottom-small {
        padding-bottom: 1.5rem;
    }

    .module-container--spacing-left-small {
        padding-left: 1.5rem;
    }
    .module-container--spacing-right-small {
        padding-right: 1.5rem;
    }
}

@include media-query(small-up-to-medium) {
    /*------------------------------------*\
      #MODULE CONTAINERS
    \*------------------------------------*/
    .module-container--spacing-top-large {
        padding-top: 3.75rem;
    }

    .module-container--spacing-bottom-large {
        padding-bottom: 3.75rem;
    }

    .module-container--spacing-left-large {
        padding-left: 3.75rem;
    }

    .module-container--spacing-right-large {
        padding-right: 3.75rem;
    }

    .module-container--spacing-top-regular {
        padding-top: 2rem;
    }

    .module-container--spacing-bottom-regular {
        padding-bottom: 2rem;
    }
    
    .module-container--spacing-right-regular {
        padding-right: 2rem;
    }
    
    .module-container--spacing-left-regular {
        padding-left: 2rem;
    }

    .module-container--spacing-top-small {
        padding-top: 1.25rem;
    }

    .module-container--spacing-bottom-small {
        padding-bottom: 1.25rem;
    }

    .module-container--spacing-right-small {
        padding-right: 1.25rem;
    }

    .module-container--spacing-left-small {
        padding-left: 1.25rem;
    }
}

@include media-query(medium) {
    /*------------------------------------*\
      #MODULE IMAGES
    \*------------------------------------*/
    .component-image--md-focal {
        object-position: var(--focal-point-x-md) var(--focal-point-y-md);
    }
}

@include media-query(small-up) {
    /*------------------------------------*\
      #MODULE COMPONENTS
    \*------------------------------------*/
    .component-overlay--small-up {
        position: absolute;
        overflow: hidden;
    }


    @for $i from 1 through 10 {
        .component-max-w--#{$i * 10} {
           max-width: $i * 10%;
        }
    }

        .module-container__header {
            &:not(:last-child) {
                margin-bottom: 2.25rem;
            }
        }
}

@include media-query(small) {
    /*------------------------------------*\
      #MODULE COMPONENTS
    \*------------------------------------*/
    .component-overlay--small {
        position: absolute;
        overflow: hidden;
    }

    /*------------------------------------*\
      #MODULE IMAGES
    \*------------------------------------*/
    .component-image--sm-focal {
        object-position: var(--focal-point-x-sm) var(--focal-point-y-sm);
    }

    /*------------------------------------*\
      #MODULE CONTAINERS
    \*------------------------------------*/

    .module-container {
        overflow: hidden;
    }

    .module-container--spacing-top-large {
        padding-top: 3rem;
    }

    .module-container--spacing-bottom-large {
        padding-bottom: 3rem;
    }

    .module-container--spacing-top-regular {
        padding-top: 1.65rem;
    }

    .module-container--spacing-bottom-regular {
        padding-bottom: 1.65rem;
    }

    .module-container--spacing-top-small {
        padding-top: 1rem;
    }

    .module-container--spacing-bottom-small {
        padding-bottom: 1rem;
    }

        .module-container__header {
            &:not(:last-child) {
                margin-bottom: 1.25rem;
            }
        }
}

.html-block {
    &.block-same-height {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-start;

        .html-block__copy,
        .html-block__content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

    }
}

#psfitguide {
    .experience-accelerator_layouts-flexibleGrid {
        .module-grid.row {
            justify-content: center;
        }

        .html-block__copy {
            .bottom_description {
                max-width: 250px;
                margin-left: auto;
                margin-right: auto;

                ul {
                    text-align: left;
                }
            }
        }

        .html-block {
            &.block-same-height {
                .html-block__actions {
                    .html-block__cta {
                        @include media-query(medium-up-to-xmedium) {
                            font-size: 0.7125rem;
                        }
                    }
                }
            }
        }

        .content-tile__aspect-ratio--inherit:before {
            @include media-query(small-up) {
                padding-bottom: 395px;
            }
        }

        .content-tile {
            @include media-query(medium-up) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        .content-tile--size-regular .content-tile__content-wrap.set--text-overlay {
            padding-bottom: 45%;
        }
    }
}

.module-container {
    position: relative;
    
    &--full-container {
        width: 100%;
    }
    &--container{
        padding-left: $gutter-outer;
        padding-right: $gutter-outer;
        margin: 0 auto;
        max-width: 90rem;
    }
}

@include media-query(medium-up) {
    .spacing-top {
        &--large {
            padding-top: 4.5rem;
        }
        &--regular {
            padding-top: 2.5rem;
        }
        &--small {
            padding-top: 1.5rem;
        }
    }
    .spacing-bottom {
        &--large {
            padding-bottom: 4.5rem;
        }
        &--regular {
            padding-bottom: 2.5rem;
        }
        &--small {
            padding-bottom: 1.5rem;
        }
    }
    .spacing-right {
        &--large {
            padding-right: 4.5rem;
        }
        &--regular {
            padding-right: 2.5rem;
        }
        &--small {
            padding-right: 1.5rem;
        }
    }
    .spacing-left {
        &--large {
            padding-left: 4.5rem;
        }
        &--regular {
            padding-left: 2.5rem;
        }
        &--small {
            padding-left: 1.5rem;
        }
    }
}

.module-banner {
    position: relative;
    display: flex;
    overflow: hidden;

    .photo-tile-figure {
        padding: 0;
        margin: 0;
        display: block;
        img {
            margin: 0 auto;
            height: auto;
            display: block;
        }
    }
    .alignment-vertical {
        &-top {
            align-content: flex-start;
        }
        &-center {
            align-content: center;
        }
        &-bottom {
            align-content: flex-end;
        }
    }
    .alignment-horizontal {
        &-left {
            justify-content: flex-start;
            .module-cta__header {
                justify-content: flex-start;
            }
        }
        &-center {
            justify-content: center;
            .module-cta__header {
                justify-content: center;
            }
        }
        &-right {
            justify-content: flex-end;
            .module-cta__header {
                justify-content: flex-end;
            }
        }
    }
    .module-banner-inner {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        max-width: 90rem;
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        padding: 30px 15px;

        &.spacing-left--large {
            @include media-query(small) { 
                padding-left: 4.5rem;
            }
        }
        
        &.spacing-right--large{
            @include media-query(small) { 
                padding-right: 4.5rem;
            }
        }

    }
    @include media-query(small-up) {
        &--full {
            height: 100vh;
        }
        &--medium {
            height: 80vh;
        }
        &--small {
            height: 50vh;
        }
        &--right {
            justify-content: flex-end;
            text-align: right;
            margin-left: auto;
        }
        &--center {
            justify-content: center;
            text-align: center;
        }
        &--left {
            justify-content: flex-start;
            text-align: left;
        }
        .spacing-top {
            &--large {
                padding-top: 4.5rem;
            }
            &--regular {
                padding-top: 2.5rem;
            }
            &--small {
                padding-top: 1.5rem;
            }
        }
        .spacing-bottom {
            &--large {
                padding-bottom: 4.5rem;
            }
            &--regular {
                padding-bottom: 2.5rem;
            }
            &--small {
                padding-bottom: 1.5rem;
            }
        }
        .spacing-left {
            &--large {
                padding-left: 4.5rem;
            }
            &--regular {
                padding-left: 2.5rem;
            }
            &--small {
                padding-left: 1.5rem;
            }
        }
        .spacing-right {
            &--large {
                padding-right: 4.5rem;
            }
            &--regular {
                padding-right: 2.5rem;
            }
            &--small {
                padding-right: 1.5rem;
            }
        }
    }
    @include media-query(small-up) {
        &--bottom {
            .module-banner-inner {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-end;
            }
        }
        &--top {
            .module-banner-inner {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
            }
        }
        &--center {
            .module-banner-inner {
                display: flex;
                flex-wrap: wrap;
                align-content: center;
            }
        }
    }

    h1,
    h2,
    h3 {
        font-family: "Playfair Display", Times, serif !important;

    }
    .editorialRichText-component-container {
        h1,
        h2,
        h3 {
            font-size: 60px;
            @include media-query(small) {
                font-size: 45px;
            }
        }
        p {
            font-size: 20px;
            @include media-query(small) {
                font-size: 18px;
            }
        }
        .text {
            &-center {
                text-align: center;
            }
            &-left {
                text-align: left;
            }
            &-right {
                text-align: right;
            }
            @include media-query(medium-up) {
                &-lg-center {
                    text-align: center;
                }
                &-lg-left {
                    text-align: left;
                }
                &-lg-right {
                    text-align: right;
                }
            }

            @include media-query(small-up) {
                &-md-center {
                    text-align: center;
                }
                &-md-left {
                    text-align: left;
                }
                &-md-right {
                    text-align: right;
                }
            }
            @include media-query(small) {
                &-sm-center {
                    text-align: center;
                }
                &-sm-left {
                    text-align: left;
                }
                &-sm-right {
                    text-align: right;
                }
            }
        }
    }

    .module-cta__header {
        flex-basis: 100%;
        max-width: 100%;
        padding: 50px 0;
        display: flex;
        flex-wrap: wrap;
        @include media-query(small) { 
            padding: 32px 0;
        }

        .module-cta__item {
            margin-right: 8px;
            @include media-query(small) { 
                margin-right: 0;
            }
            .button {
                border-color: #000;
                margin-bottom: 8px;
                &:hover {
                    opacity: 1;
                    background-color: white;
                }
            }
        }
    }
}

.module-container {
    position: relative;
    
    &--full-container {
        width: 100%;
    }
    &--container{
        padding-left: $gutter-outer;
        padding-right: $gutter-outer;
        margin: 0 auto;
        max-width: 90rem;
    }
}

@include media-query(medium-up) {
    .spacing-top {
        &--large {
            padding-top: 4.5rem;
        }
        &--regular {
            padding-top: 2.5rem;
        }
        &--small {
            padding-top: 1.5rem;
        }
    }
    .spacing-bottom {
        &--large {
            padding-bottom: 4.5rem;
        }
        &--regular {
            padding-bottom: 2.5rem;
        }
        &--small {
            padding-bottom: 1.5rem;
        }
    }
}