/**
 * FixIt base styles. These can be overriden if necessary.
 */

.fixit-container {
	position: relative;
}

.fixit-element {
	&.fixit--active {
		position: fixed;
		// transform: translate3d(0,0,0); //Fixes iOS flicker/lag.

		top: 0;

		&:not(.fixit--respond-to-parent) {
			width: 100%;
		}
	}

	&.fixit--bottom,
	&.fixit--docked {
		top: auto;
		bottom: 0;
	}

	&.fixit--frozen,
	&.fixit--docked {
		position: absolute;
	}
}

//Adds higher z-index when the fixed element should overlay others.
.fixit-element--overlay {
	z-index: 1010;
}
