.toggle-box {
    padding-top: .35rem;
    padding-bottom: .35rem;
    border-bottom: solid .0625rem $color-grey-3;
}

.toggle-box__label {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 3.25rem;
    cursor: pointer;

    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 1rem;
        height: 1rem;
        line-height: .7;
        content: url(get-icon(plus, $color-black));
    }

    &.toggle--active {
        &:after {
            content: url(get-icon(minus, $color-black));
        }
    }
}

.toggle-box__content {
    margin-bottom: 1.25rem;

    &:not(.toggle--active) {
        display: none;
    }
}