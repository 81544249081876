
/*------------------------------------*\
  #INPUT-RESETS
\*------------------------------------*/

input[type="password"],
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="search"],
input[type="number"],
select,
textarea {
    font-family: inherit;
    font-weight: $font-weight-light;
    font-size: $font-size-deka;
    appearance: none;
    border-radius: $input-border-radius;
    border: solid .0625rem $color-grey-2;
    outline: none;
    transition-property: color, border-color;
    transition-duration: $transition-speed-fast;
    transition-timing-function: $transition-ease-default;

    &::placeholder {
        color: $color-grey-4;
    }

    &:focus {
        &::placeholder {
            color: $color-grey-3;
        }
    }

    &.disabled, &[disabled] {
        color: $color-grey-4;
    }

    &:not([disabled]):not([readonly]):not(.is-invalid):focus {
        border-color: $color-grey-7;
    }
}

input[type="file"] {
    cursor: pointer;

    &.disabled, &[disabled] {
        cursor: not-allowed;
    }
}

//Moves Safari's autofill icon to the left so that it doesn't
//overlay the tooltip icon.
input::-webkit-contacts-auto-fill-button {
    position: relative;
    right: 1.5rem;
}

textarea {
    display: block;
    width: 100%;
    resize: none;
}

select {
    // border: none;
    color: inherit;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::-ms-expand {
        display: none;
    }
}

option {
    color: $color-body-copy;
}

/*------------------------------------*\
  #FORM-COPY
\*------------------------------------*/
.form-intro__title {
    margin-bottom: .75em;
}

.form-intro__copy {
    margin-bottom: 1.75em;
}

.form-control-disclaimer {
    margin-top: .75em;
}

.form-control-description {
    margin-top: .25em;
    font-size: $font-size-deci;
    color: $color-grey-5;
}

/*------------------------------------*\
  #TEXT AND SELECTS
\*------------------------------------*/
.form-control {
    width: 100%;
    height: 3rem;
    padding-right: 1rem;
    padding-left: 1rem;
    background-color: $color-white;

    &.is-invalid {
        border-color: $color-error;
        background-color: lighten($color-error, 52%);
        color: $color-error;
    }

    &.remove--border-styles {
        border-radius: 0;
        border-color: transparent;
    }
}

.form-control--small {
    height: 2.5rem;
}

//Add custom styles for text input fields.
.form-control--text {

}

.form-control--textarea {
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: auto;
    min-height: 4.5rem;
}

.custom-select,
.form-control--select {
    padding-right: 1.5rem;
    padding-left: .875rem;
    background-size: .6em .6em; //Provide height + width separately to fix IE display.
    background-repeat: no-repeat;
    background-position: calc(100% - .7em) center;
    background-image: url(get-icon(angle-down, $color-body-copy));
    transition: color $transition-speed-faster $transition-ease-default;

    &:-internal-autofill-selected {
        background-image: url(get-icon(angle-down, $color-body-copy)) !important;
    }
}

.form-control-label {
    display: inline-block;
    font-size: $font-size-centi;
    margin-bottom: .25rem;

    .required &:after {
        content: "*";
        color: $color-error;
    }
}

.form-control-label--inline {

}

/*------------------------------------*\
  #CHECKBOXES AND RADIOS
\*------------------------------------*/
.form-check {
    position: relative;
}

.form-check-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

[class*="form-check-label"] {
    display: block;
    position: relative;
    padding-left: 2.1em;
    line-height: 1.3; //Aligns label with checkbox. Intentionally not a variable.
    transition: color $transition-speed-fast $transition-ease-bezier;

    &:before, &:after {
        position: absolute;
    }

    &:before {
        top: 0;
        left: 0;
        border: solid .0625rem;
        border-radius: $input-border-radius;
        content: '';
        width: 1.125rem;
        height: 1.125rem;
    }

    &:after {
        top: 0;
        left: 0;
        width: 1.125rem;
        height: 1.125rem;
        transform: scale(0);
    }
}

.form-check-label {
    &:after {
        opacity: 0;
        line-height: $font-line-height-hecto;
        transition: opacity $transition-speed-fast $transition-ease-bezier;
        content: url(get-icon(checkmark, $color-primary));
    }
}

.form-check-label--radio {
    &:before, &:after {
        border-radius: 50%;
    }

    &:after {
        background-color: $color-primary;
        transform-origin: center;
        transition: transform $transition-speed-fast $transition-ease-bezier;
        content: '';
    }
}

.form-check-input:checked + .form-check-label,
.form-check-label.set--checked {
    color: inherit;

    &:before {
        border-color: $color-primary;
    }

    &:after {
        opacity: 1;
        transform: scale(.75);
    }
}

.form-check-input:checked + .form-check-label--radio,
.form-check-label--radio.set--checked {
    &:after {
        opacity: 1;
        background-color: $color-primary;
        transform: scale(.6);
    }
}

.form-check-input:focus + [class*="form-check-label"],
[class*="form-check-label"]:focus {
    // outline: none;

    &:before {
        border-color: $color-accent-tertiary;
    }
}

.form-check-input:disabled + [class*="form-check-label"],
[class*="form-check-label"].disabled {
    &:before {
        background-color: $color-grey-1;
        border-color: $color-grey-2;
    }

    &:after {
        color: $color-grey-2;
    }
}

.form-check-input.error + [class*="form-check-label"] {
    &:before {
        border-color: $color-error;
    }
}

/*------------------------------------*\
  #FORM GROUPS
\*------------------------------------*/
.form-group {
    margin-bottom: 1rem;
}

.form-group--no-spacing {
    margin-bottom: 0;
}

.form-group--plus-sign {
    position: relative;

    &:before {
        position: absolute;
        left: 0.8rem;
        top: 2.3rem;
        width: 1rem;
        height: 1rem;
        line-height: 100%;
        font-size: $font-size-deka;
        font-weight: $font-weight-extralight;

        content: '+';
    }

    .form-control {
        padding-left: 2rem;
    }
}

.form-check-list__item {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

//Use this to separate entire sections within a form or groups of forms.
.form-section {
    &:not(:first-child) {
        margin-top: 1.6rem;
    }
}

.form-combo {
    position: relative;
}

.form-combo--underline {
    border-bottom: solid .125rem $color-grey-2;

    .form-combo__input {
        padding-left: 0;
    }

    .form-combo__action {
        padding-left: 1rem;
    }
}

/*------------------------------------*\
  #FORM ACTIONS
\*------------------------------------*/

//Ensure this class is added on a block element, so that bottom/top  margins are collapsed.
.form-actions {
    margin-top: 2.4rem;
}

.form-actions--large-spacing {
    margin-top: 4rem;
}

    .form-actions__stack-item {
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }

/*------------------------------------*\
  #FORM VALIDATION
\*------------------------------------*/
.success-feedback,
.invalid-feedback,
.form-invalid-feedback {
    display: block;
    margin-top: .25rem;
    font-size: $font-size-centi;

    &:not(.set--visible) {
        position: absolute;
        @include set-invisible();
    }
}

.invalid-feedback,
.form-invalid-feedback {
    color: $color-error;
}

.success-feedback {
    color: $color-success;
}

.form-invalid-feedback {
    margin-bottom: 1em;
}

/*------------------------------------*\
  #FORM CONSTRAINTS
\*------------------------------------*/

.form-control__constraints {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: .75em;
}

    .form-control__constraint {
        flex: 1 1 50%;
    }

    .set--constraint-fail {
        color: $color-error;
    }

    .set--constraint-pass {
        color: $color-success;
    }

/*------------------------------------*\
  #FLOATING LABELS
\*------------------------------------*/

.form-group--floating-label {
    position: relative;
    padding-top: .625rem;

    .form-control-label {
        position: absolute;
        top: .625rem;
        left: 0;
        line-height: 2.5rem;
        transform-origin: top left;
        transition: opacity $transition-speed-faster $transition-ease-default,
                    top $transition-speed-faster $transition-ease-default,
                    transform $transition-speed-faster $transition-ease-default;
        cursor: text;
    }

    &.set--floating-label {
        .form-control-label {
            top: -1rem;
            transform: scale(calc(12/14));
        }
    }

    &:not(.set--floating-label) {
        .form-control-label {
            pointer-events: none;
            color: $color-grey-4;
        }

        .form-control {
            &::placeholder {
                color: transparent;
            }

            //This seems to be required for IE.
            &:-ms-input-placeholder {
                color: transparent;
            }
        }

        .form-control--select {
            //IE doesn't like straight transparency here.
            color: transparentize($color-white, 0.99);
        }
    }
}

@include media-query(small-up) {
    /*------------------------------------*\
      #FORM GROUPS
    \*------------------------------------*/

    .form-combo {
        .form-combo__input {
            padding-right: 1rem;
        }
    }
}

@media (-ms-high-contrast: active) {
    .form-check-label {
        &:after {
            content: url(get-icon(checkmark, $color-white));
        }
    }

    .form-check-label--radio {
        &:after {
            border: solid .5rem;
        }
    }
}
