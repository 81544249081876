
.consent-notification {
    z-index: 100;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(0);
    transition-property: opacity, visibility, transform;
    transition-duration: $transition-speed-fast;
    transition-timing-function: $transition-ease-out;

    &:not(.set--consent-visible) {
        transform: translateY(100%);
        @include set-invisible();
    }
}

@include media-query(small-up) {
    .consent-notification {
        padding: 1rem 1rem 2rem;
        border: solid .0625rem;
        border-bottom: none;
    }

        .consent-notification__action {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
}

@include media-query(small) {
    .consent-notification {
        padding: 1rem 1rem 4.5rem;
        border-top: solid .0625rem;
    }

        .consent-notification__actions {
            margin-top: 1.25rem;
        }
}
