
.header-flyout__anchor {
    text-transform: uppercase;
    font-family: $font-family-sans-secondary;

    &.level-3 {
        font-weight: $font-weight-light;
    }
}

@include media-query('xmedium-up') {
    .header-flyout__anchor {
        font-size: $font-size-deci;

        &.level-1 {
            padding: .85em;
        }
    }
}

@include media-query('medium-up') {
    .header-flyout {
        margin-left: 1.75%;
    }
}

@include media-query('small-up-to-xmedium') {
    .header-flyout__anchor {
        font-size: $font-size-centi;

        &.level-1 {
            padding: .75rem;
        }
    }
}

@include media-query('small-up-to-medium') {
    .header-flyout {
        margin-top: .5rem;
        margin-left: -.75rem;
    }
}

@include media-query('small-up') {
    .header-flyout {
        &:hover,
        &.mega--active {
            .header-flyout__anchor.level-1:not(.mega-item--active):not(:hover) {
                opacity: .6;
            }
        }
    }

    .header-flyout__tile {
        // transition-property: opacity, transform;
        // transition-duration: $transition-speed-medium;
        // transition-timing-function: $transition-ease-in;

        // opacity: 0;
        // transform: translateY(2rem);
    }

    .header-flyout__list {
        &.level-1 {
            display: flex;
        }

        &.level-2, &.level-3 {
            // transition-property: opacity, transform;
            // transition-duration: $transition-speed-medium;
            // transition-timing-function: $transition-ease-in;

            // opacity: 0;
        }

        &.level-2 {
            position: relative;
            // align-self: flex-start;
            margin-top: 1.25rem;
            // max-width: 14.5rem;
            column-count: 4;

            // transform: translateY(2.5rem);
        }

        &.level-3 {
            // transform: translateX(-2.5rem);
        }
    }

        .header-flyout__item {
            &.level-2 {
                overflow: hidden;
                break-inside: avoid;
                //Prevent focus outline from cutting off
                padding-top: .5rem;
                padding-right: .5rem;
                padding-left: .5rem;

                &:not(:last-child) {
                    padding-bottom: 1rem;
                }
            }

            &.level-3 {
                &:not(:last-child) {
                    margin-bottom: .75em;
                }
            }
        }

        // .header-flyout__item--main {
        //     &.level-2 {
        //         column-span: all;
        //     }
        // }

        .header-flyout__anchor {
            &.level-1 {
                display: block;
                letter-spacing: .1em;
                transition-property: opacity, box-shadow;
                transition-duration: $transition-speed-fast;
                transition-timing-function: $transition-ease-out;
            }

            &.level-2, &.level-3 {
                display: inline-block;
                letter-spacing: 0.066667em;
                transition-property: color, opacity, transform, -webkit-text-stroke-width;
                transition-duration: $transition-speed-fast;
                transition-timing-function: $transition-ease-in;

                // opacity: 0;

                // &.mega-item--set-transitioned {
                //     opacity: 1;
                //     transform: translate(0);
                //     transition-timing-function: $transition-ease-out;
                // }
            }

            &.level-2 {
                font-weight: $font-weight-semibold;
                // transform: translateY(1.5rem);

                &:not(:last-child) {
                    margin-bottom: 1em;
                }

                &:hover {
                    text-decoration: underline;
                }

                // &:hover, &:focus, &.mega-item--active {
                //     .header-flyout__anchor-icon {
                //         opacity: 1;
                //         transform: translateX(0);
                //     }
                // }
            }

            &.level-3 {
                color: $color-grey-5;
                // transform: translateX(-1.5rem);
                -webkit-text-stroke-width: 0;

                &:hover, &:focus {
                    color: $color-grey-7;
                    -webkit-text-stroke-width: .0625rem;
                }
            }
        }

            // .header-flyout__anchor-icon {
            //     opacity: 0;
            //     transform: translateX(-.5rem);

            //     transition-property: opacity, transform;
            //     transition-duration: $transition-speed-fast;
            //     transition-timing-function: $transition-ease-bezier;
            // }

        .header-flyout__container {
            &.level-2 {
                z-index: 1;
                position: absolute;
                transition-property: opacity, visibility;
                transition-duration: $transition-speed-fast;
                transition-timing-function: $transition-ease-default;

                &:not(.mega-item--active) {
                    @include set-invisible();
                    display: none;
                }
            }

            .mega--active &.mega-item--active.level-2 {
                transition-duration: 0s;
            }

            &.level-2 {
                top: 100%;
                left: 0;
                width: 100%;
                min-height: 24rem;
                padding-bottom: 3.75rem;
                background-color: $color-white;

                &.mega-item--active {
                    z-index: 0; //Forces fade transition to remain smooth,
                                //this works because the non-active container
                                //keeps a higher z-index.

                    .header-flyout__tile.level-2,
                    .header-flyout__list.level-2 {
                        opacity: 1;
                        transform: translateY(0);
                        transition-timing-function: $transition-ease-out;
                        transition-duration: $transition-speed-fast;
                    }
                }
            }

            &.level-3 {
                // top: 0;
                // left: calc(100% + 4rem);
                // min-width: 20rem;

                // &.mega-item--active {
                //     .header-flyout__list.level-3 {
                //         opacity: 1;
                //         transform: translateX(0);
                //         transition-timing-function: $transition-ease-out;
                //         transition-duration: $transition-speed-fast;
                //     }
                // }
            }
        }

        .header-flyout__mixed-row {
            justify-content: flex-end;
            flex-flow: row-reverse;
        }
}

@include media-query('small') {
    .header-flyout {
        z-index: 1;
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: $color-white;
        overflow: hidden;
        transition-property: opacity, visibility;
        transition-timing-function: $transition-ease-bezier;

        &:not(.toggle--active) {
            @include set-invisible();
            transition-duration: $transition-speed-medium;
        }

        &.toggle--active {
            @include set-visible();
            transition-duration: $transition-speed-normal;
        }
    }

            .header-flyout__head {
                position: relative;

                &.level-2 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 4.75rem;
                    border-bottom: solid .0625rem $color-grey-2;
                }
            }

                .header-flyout__back {
                    &.level-2 {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        padding: 1.25rem;
                    }
                }

                .header-flyout__head-title {
                    font-size: $font-size-deka;
                    font-weight: $font-weight-semibold;
                    text-transform: uppercase;
                }

            .header-flyout__close {
                z-index: 10;
                position: absolute;
                top: .875rem;
                right: 0;
                padding: 1rem 1.25rem;
            }

        .header-flyout__container {
            &.level-1 {
                position: relative;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                max-height: 100%;
            }

            &.level-2 {
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $color-white;
                transition-property: opacity, visibility, transform;
                transition-duration: $transition-speed-medium;
                transition-timing-function: $transition-ease-bezier;

                &.mega-item--active {
                    @include set-visible();
                    transform: translateX(0);
                }

                &:not(.mega-item--active) {
                    @include set-invisible();
                    transform: translateX(-100%);
                }
            }

            &.level-3 {
                -webkit-overflow-scrolling: touch;
                overflow: auto;

                transition-property: opacity, visibility, max-height;
                transition-duration: $transition-speed-fast;

                &.mega-item--active {
                    max-height: 28.75rem;
                    @include set-visible();

                    transition-timing-function: $transition-ease-out;
                }

                &:not(.mega-item--active) {
                    max-height: 0;
                    @include set-invisible();

                    transition-timing-function: $transition-ease-in;
                }
            }
        }

            .header-flyout__mixed-row {
                justify-content: center;
            }

            .header-flyout__scrollable {
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 100%;
                -webkit-overflow-scrolling: touch;

                &.level-1, &.level-2 {
                    padding-bottom: 6rem;
                }

                &.level-2 {
                    //Substract .header-flyout__head.level-2's height.
                    max-height: calc(100% - 4.75rem);
                }
            }

            .header-flyout__list {
                &.level-1 {
                    margin-top: .4rem;
                }
            }

            .header-flyout__item {
                &.level-2 {
                    border-bottom: solid .0625rem $color-grey-2;
                }
            }

            .header-flyout__content-item {
                margin-bottom: 1.25rem;
            }

            .header-flyout__anchor,
            .header-flyout__utility-anchor {
                opacity: 0;

                transition-property: opacity, transform;
                transition-duration: $transition-speed-medium;
                transition-timing-function: $transition-ease-bezier;

                &.level-1 {
                    transform: translateY(1.5rem);
                }

                &.level-2, &.level-3 {
                    transform: translateX(-1.5rem);
                }

                &.mega-item--set-transitioned {
                    opacity: 1;
                    transform: translate(0);
                }
            }

            .header-flyout__anchor {
                font-size: $font-size-deka;
                letter-spacing: .0625rem;

                &.level-1,
                &.level-2 {
                    font-weight: $font-weight-semibold;
                }

                &.level-1 {
                    display: block;
                    padding: 1.6rem;
                    text-align: center;
                }

                &.level-2 {
                    display: flex;
                    justify-content: space-between;
                    padding: 1.6rem 1rem;

                    &.mega-item--active {
                        .header-flyout__anchor-icon--expand {
                            display: none;
                        }

                        .header-flyout__anchor-icon--collapse {
                            display: block;
                        }
                    }
                }

                &.level-3 {
                    display: block;
                    padding: 1.125rem 1.25rem 1.125rem 2.5rem;
                }
            }

            .header-flyout__anchor--parent {
                font-weight: $font-weight-bold;
            }

                .header-flyout__anchor-icon {

                }

                .header-flyout__anchor-icon--expand {

                }

                .header-flyout__anchor-icon--collapse {
                    display: none;
                }

            .header-flyout__utility {
                margin-top: 1.6rem;
            }

                .header-flyout__utility-anchor--stacked {
                    padding-top: 1.25rem;
                    padding-bottom: 1.25rem;
                    border-top: solid .0625rem $color-grey-2;
                    border-bottom: solid .0625rem $color-grey-2;
                }

                .header-flyout__utility-anchor--unstacked {
                    margin-top: .625rem;
                    padding-top: .625rem;
                    padding-bottom: .625rem;

                    &:not(:last-child) {
                        border-right: solid .0625rem $color-grey-2;
                    }
                }

                    .header-flyout__utility-description {
                        margin-bottom: .75rem;
                        padding-right: 3.25rem;
                        padding-left: 3.25rem;
                    }

                    .header-flyout__utility-icon {
                        margin-bottom: .5rem;
                    }

                    .header-flyout__utility-label {
                        letter-spacing: .0625rem;
                    }
}

.header-flyout {
    &__list {
        .header-flyout__item {
            &:first-child {
                a#flyout-GiftGuide2024 {
                    position: relative;
                    z-index: 2;
                    @include media-query('small') {
                        z-index: unset;
                    }
                    &::after {
                        content: "";
                        top: 50%;
                        left: 50%;
                        width: 86px;
                        height: 36px;
                        position: absolute;
                        z-index: -1;
                        background: url('../images/bg-no-stars.gif') no-repeat 0 0;
                        background-size: 100% 100%;
                        transform: translate(-50%, -50%);
                    }

                }
            }
        }
    }
}