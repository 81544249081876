
.tooltip {
    display: inline-block;
    position: relative;
    width: 1rem;
    height: 1rem;
    margin-left: .125rem;
    vertical-align: middle;
}

.tooltip--field-label {
    margin-bottom: .125rem;
}

    .tooltip__trigger {
        display: flex;
        height: 100%;
        cursor: pointer;
    }

        .tooltip__trigger-icon {
            width: 100%;
            height: 100%;
        }

    .tooltip__content {
        z-index: 2;
        position: absolute;
        min-width: 14.5rem;
        padding: .6em;
        border: solid .0625rem $color-grey-7;
        border-radius: $input-border-radius;
        transition: opacity $transition-speed-fast $transition-ease-bezier,
                    visibility $transition-speed-fast $transition-ease-bezier,
                    transform $transition-speed-fast $transition-ease-bezier;

        @include set-invisible();

        &:before, &:after {
            position: absolute;
            width: 0;
            height: 0;
            content: '';
        }

        &.set--top {
            bottom: calc(100% + 1.125rem);
            transform: translateY(.5rem);

            @include tooltip-arrows();
        }

        &.set--right {
            right: -.25rem;

            &:before {
                right: .75rem;
                border-left: .8125rem solid transparent;
            }
        }

        &.set--left {
            left: -.25rem;

            &:before {
                left: .75rem;
                border-right: .8125rem solid transparent;
            }
        }

        &.set--center {
            left: 50%;
            transform: translateX(-50%);
        }

        &.set--bottom {
            top: calc(100% + .75rem);
            transform: translateY(-.5rem);

            @include tooltip-arrows(bottom);
        }

        &.set--tooltip-active {
            &:not(.set--center) {
                transform: translateY(0);
            }

            &.set--center {
                transform: translate(-50%, 0);
            }

            @include set-visible();
        }
    }
