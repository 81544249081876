@import "base/components/_toastMessage";
.product-tile {
    position: relative;
    @include media-query(medium-up) {

        &:hover {
            .product-tile__swatch-count {
                opacity: 0;
            }
    
            .product-tile__swatch {
                @include set-visible();
            }
            .quickAdd-container {
                display: block;
            }
        }
    }
    .quickAdd-container{
        display: none;
        background-color: white;
        position: absolute;
        z-index: 1;
        bottom: 1rem;
        left: 0;
        right: 0;
        width: 90%;
        margin: 0 auto;
        padding: 1rem 0.5rem;
        text-align: center;
        &:hover {
            padding: 0.5rem 0.5rem;
            .quick-add-overlay {
                display: block;
            }
            .quickAdd-label {
                display: none;
            }
        }
        .quickAdd-label {
            font-weight: 400;
        }
        .quick-add-overlay{
            display: none;
            .add-to-cart{
                display: none;
            }
            .tile-add-to-cart-one-size{
                font-size: 0.8rem;
                font-weight: 400;
                &:hover {
                    font-weight: 500;
                    text-decoration: underline
                }
                &:disabled{
                    color: #ab9e9e;
                    text-decoration: line-through;
                    font-weight: 400;
                }
            }
            .variation-attributes {
                margin: 0.5rem 0;
                .variation-attribute {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    .value{
                        margin: 0.5rem 0;
                        justify-content: center;
                        button span{
                            padding: 0.75rem 0.75rem;
                            margin: 2rem 0;
                            &.selectable:hover {
                                text-decoration: underline;
                                font-weight: 500;
                            }
                            &.unselectable {
                                color: #ab9e9e;
                                text-decoration: line-through;
                            }
                            &.selected {
                                text-decoration: underline;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
}

.add-to-cart-messages {
    @include toast-message();
    z-index: 999;
    .alert-danger {
        background-color: rgb(247, 162, 162);
    }
    .alert-success {
        background-color: rgb(164, 248, 153);
    }
}

.add-to-basket-alert {
    @include toast-alert();
    z-index: 999;
}

.product-tile--overlay {
    padding: 1.25rem;
}

    .product-tile__anchor {
        display: block;
    }

    .product-tile__body {
        position: relative;
        padding-top: .6rem;
    }

    .product-tile__media--default {
        display: block;
        overflow: hidden;

        @include aspect-ratio(309, 433, true);

        &:hover {
            .product-tile__image {
                transition-timing-function: $transition-ease-out;
            }

            .product-tile__image--secondary.lazyloaded {
               @include set-visible();
            }
        }
    }

        .product-tile__media-container {
            display: block;
        }

        .product-tile__image {
            max-height: none;
            transition-property: opacity, visibility;
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-in;
        }

        .product-tile__image--secondary {
            @include set-invisible();
        }

    .product-tile__body-section {
        &:not(:first-child) {
            margin-top: .5rem;
        }
    }

    .product-tile__swatches {
        display: flex;
        position: relative;
        margin-top: .5rem;

        &.set--show-swatches {
            .product-tile__swatch-count {
                opacity: 0;
            }

            .product-tile__swatch {
                @include set-visible();
            }
        }
    }

        .product-tile__swatch {
            @include set-invisible();

            &:not(:last-child) {
                margin-right: .5rem;
            }
        }

        .product-tile__swatch-count {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        .product-tile__swatch,
        .product-tile__swatch-count {
            transition-property: opacity, visibility;
            transition-timing-function: $transition-ease-in;
            transition-duration: $transition-speed-fast;
        }

    .product-tile__quickview {
        position: relative;
        top: 1rem;
        width: 100%;
        @media screen and (min-width: 64.0625rem) {
            opacity: 0;
            .product-tile:hover &,
            &:focus {
                opacity: 1;
            }
        }
    }

    .product-tile__wishlist {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        padding: .5em;

        .product-wishlist__add {
            opacity: .5;
        }
    }

        .product-tile__badge-container {
            z-index: 1;
            position: absolute;
            top: .5rem;
            left: .5rem;
            opacity: .85;
        }

            .product-tile__badge-text {
                display: block;
                &.new-arrival {
                    text-align: center;
                    padding: 0 .3rem;
                    background-color: white;
                    color: black;
                }
            }

.pdp__recommendations {
    .slick-initialized .slick-slide {
        @media screen and (min-width: 64.0625rem) {
            height: 100%;
        }
    }
}