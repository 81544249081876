
.dropdown-box {
    position: relative;
    font-family: $font-family-sans-secondary;
    text-transform: uppercase;
    letter-spacing: .0666667em;
}

    .dropdown-box__trigger {
        border: solid .0625rem $color-grey-2;
    }

    .dropdown-box__list {
        z-index: 2;
        position: absolute;
        top: calc(100% - .0625rem);
        right: 0;
        min-width: 100%;
        background-color: $color-white;
        border: solid .0625rem $color-grey-2;

        &:not(.toggle--active) {
            @include set-invisible();
        }
    }

    .dropdown-box__item {
        &:not(:last-child) {
            border-bottom: solid .0625rem $color-grey-1;
        }
    }

    .dropdown-box__anchor {
        display: block;

        &:hover, &:focus {
            background-color: $color-grey-1;
        }

        &.selected {
            background-color: $color-grey-1;
            font-weight: $font-weight-semibold;
            color: $color-primary;
        }
    }

@include media-query(small-up) {
    .dropdown-box__anchor {
        padding: .75rem 1rem;
    }
}

@include media-query(small) {
    .dropdown-box__list--small-expanded {
        position: static;
        border: none;

        &:not(.toggle--active) {
            @include set-visible();
        }
    }

    .dropdown-box__anchor {
        padding: $gutter-outer-centi;
    }
}