.overlay-panel {
    z-index: 11;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    background-color: $color-body-background;
    cursor: default; //Resets cursor when used in combination with an overlay.

    &.toggle--active {
        transition: transform $transition-speed-fast $transition-ease-default,
                    visibility 0s;
    }

    &:not(.toggle--active) {
        visibility: hidden;
        transform: translateX(100%);
        transition: transform $transition-speed-fast $transition-ease-default,
                    visibility 0s $transition-ease-default $transition-speed-fast;
    }
}

.overlay-panel__header,
.overlay-panel__footer {
    flex-shrink: 0;
}

.overlay-panel__header {
    border-bottom: solid .0625rem $color-grey-2;

    .free-shipping {
        font-family: $font-family-sans-secondary;
        font-weight: $font-weight-semibold;
        color: $color-grey-8;
        font-size: $font-size-milli;
        letter-spacing: 0.04rem;
    }
}

.overlay-panel__body {
    flex-shrink: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @include media-query(small) {
        overflow: hidden;
    }
}

.overlay-panel__footer {
    margin-top: auto;
    padding-top: 0;
    padding-bottom: 0.9rem;
}

.overlay-panel__footer-section {
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: $color-grey-1;
    border-bottom: solid .0625rem $color-grey-2;

    &:not(:first-child) {
        border-top: solid .0625rem $color-grey-2;
    }
}

@include media-query(small-up) {
    .overlay-panel {
        width: 30.125rem;
    }

        .overlay-panel__header {
            padding: 1rem 2rem 1rem;
        }
}

@include media-query(small) {
    .overlay-panel {
        width: 100%;
    }

        .overlay-panel__header {
            padding: 1.75rem 1.5rem 1.6rem;
        }
}