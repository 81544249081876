
.header__utility-overlay {
    z-index: 5;
    position: absolute;
    width: 100vw;
    top: 100%;
    right: 0;
    background-color: $color-white;

    transition-property: opacity, visibility;
    transition-duration: $transition-speed-medium;
    transition-timing-function: $transition-ease-bezier;

    &:not(.toggle--active) {
        @include set-invisible();
        pointer-events: none;
    }
}

    .header__utility-overlay-close {
        position: absolute;
    }

@include media-query(large-up) {
    .header__utility-overlay {
        padding-top: 6.25rem;
    }

    .header__utility-overlay-title {
        margin-bottom: 2.85rem;
    }

    .header__utility-overlay-close {
        top: 1.5rem;
        left: calc(50vw + 41.5rem);
        transform: translateX(-100%);
    }
}

@include media-query(medium-up) {
    .header__utility-overlay {
        height: calc(100vh - 3.875rem);
    }
}

@include media-query(small-up-to-large) {
    .header__utility-overlay {
        padding-top: 7vw;
    }

    .header__utility-overlay-title {
        margin-bottom: 3.2vw;
    }

    .header__utility-overlay-close {
        top: 1.5rem;
        right: $gutter-outer;
    }
}

@include media-query(small-up-to-medium) {
    .header__utility-overlay {
        height: calc(100vh - 6.5rem);
    }
}

@include media-query(small) {
    .header__utility-overlay {
        //2.5rem is the same value used as padding-bottom in the .header element.
        top: calc(100% - 2.5rem);
        height: calc(100vh - 3.125rem);
        padding-top: 1.5rem;
        border-top: solid .0625rem $color-grey-2;
    }

        .header__utility-overlay-close {
            top: 1.2rem;
            right: .5rem;
            padding: .75rem;
        }

        .header__utility-overlay-title {
            margin-bottom: 4.5vw;
        }
}
