
.promo-banner {
    padding-top: .125rem;
    padding-bottom: .125rem;
}

.promo-banner__actions {
    margin-left: .25rem
}

.promo-banner__cta {
    &:not(:last-child) {
        margin-right: .25rem;
    }
}