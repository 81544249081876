
.header__search {

}

    .site-search__form {
        max-height: 100%;
    }

    .site-search__form-group {
        position: relative;
    }

    .site-search__field {
        width: 100%;
        padding: .5rem 2rem .5rem 0;

        //Force higher selector weight.
        .site-search & {
            border: none;
            border-bottom: solid .0625rem;

            &::placeholder {
                color: $color-grey-7;
            }
        }

        //In case there are transitions applied to the containing form element.
        //This allows focus to be shifted even if the form is still invisible (in transition).
        .toggle--active & {
            visibility: visible;
        }
    }

    .site-search__button {
        position: absolute;
        top: 0;
        right: 0;
        width: 2rem;
        height: 100%;
    }

.site-search__suggestions-container {
    position: relative;
    overflow: hidden;
    min-height: 12.5rem;
}

    .site-search__suggestions-list {
        // max-height: 65vh;
        flex: 1 1 100%;
        padding-top: 1.25rem;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

        .site-search__suggestions-corrections {
            margin-top: .25rem;
            margin-bottom: .25rem;
        }

        .site-search__suggestions-section {
            margin-bottom: 3.25rem;
        }

            .site-search__suggestions-title {
                margin-bottom: 1.125rem;
            }

            .site-search__suggestions-item {
                &:not(:last-child) {
                    margin-bottom: .75rem;
                }
            }

                .site-search__suggestions-image-wrap {
                    overflow: hidden;
                    @include aspect-ratio(1, 1, true);
                }

                .site-search__suggestions-image {
                    max-height: none;
                    @include vertical-align(true);
                }

                .site-search__suggestions-banner {
                    margin-bottom: .5rem;
                }

        .site-search__suggestions-viewall {
            margin-top: 1.25rem;
            margin-bottom: 4rem;
        }


@include media-query(small) {
    .site-search__trigger {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2.5rem;
        padding: 0 $gutter-outer-centi;
        background-color: $color-white;
        border-top: solid .0625rem $color-grey-2;
        border-bottom: solid .0625rem $color-grey-2;

        &.toggle--active {
            @include set-invisible();
        }
    }

    .site-search__suggestions-list {
        padding-bottom: 5rem;
    }
}
