
.services-item__img {
    max-width: 3.125rem; 
    margin: 0 auto .5rem;
}

.services-item__actions {
    margin-top: 1rem;
    margin-right: -.25rem;
    margin-left: -.25rem;
}

    .services-item__cta {
        margin-right: .25rem;
        margin-left: .25rem;

        &:not(:last-child) {
            margin-bottom: .75rem
        }
    }