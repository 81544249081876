.site-contact__info-section {
    &:not(:last-child) {
        margin-bottom: .5rem;
    }
}

.site-contact__info-list {
    margin-bottom: 3rem;
}

@include media-query(small-up) {
    .site-contact__info-item {
        &:not(:last-child) {
            border-right: solid .0625rem $color-grey-2;
        }
    }
}

@include media-query(small) {
    .site-contact__info-list {
        margin-top: 3rem;
    }

        .site-contact__info-item {
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }

            .site-contact__info-section {
                justify-content: center;
                text-align: center;
            }
}