/**
 * Contains global styles for the header.
 * This stylesheet should not include component specific styles.
 */

.header-container {
    z-index: 3;
    position: relative;
    cursor: initial;
}

    .header {
        background-color: $color-white;
        box-shadow: inset 0 -.0625rem 0 0 $color-grey-2;
    }

    .header__main--no-menu {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .header__logo {
        height: 2.0875rem;
    }

        .header__logo-img {
            width: 100%;
            fill: currentColor;
        }

    .header__skip-to-main {
        position: absolute;
        top: 1rem;
        left: 2rem;
        z-index: 1000;

        &:not(:focus) {
            pointer-events: none;
            opacity: 0;
        }
    }

@include media-query(medium-up) {
    .header__menu {
        flex-direction: row;
        align-items: center;
    }
}

@include media-query(small-up-to-medium) {
    //Flip the order of the menu and utility bars at this breakpoint
    //to avoid setting the utility bar as "absolute" thus causing issues with
    //the search overlay.
    .header__menu {
        order: 2;
    }

    .header__utility-bar {
        order: 1;
        margin-left: auto;
        margin-bottom: -1.5rem;
        z-index: 9;
    }
}

@include media-query(small-up) {
    .header-container {
        .set--header-blend & {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
        }
    }

        .header {
            transition-property: background-color, color, box-shadow;
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-out;

            .set--header-blend & {
                &:not(:hover):not(:focus-within):not(.fixit--active):not(.focus-within) {
                    background-color: transparent;
                    color: $color-white;
                    box-shadow: inset 0 -.0625rem 0 0 transparent;
                    transition-timing-function: $transition-ease-in;
                }
            }
        }

    .header__main--global {
        padding-top: .75rem;
        padding-bottom: .75rem;
    }

        .header__logo {
            max-width: 10rem;
        }

        .header__logo--no-menu {
            margin: auto;
        }

        .header__utility-item {
            &:not(:last-child) {
                margin-right: 1.75rem;
            }
        }
}

@include media-query(small) {
    .header--global {
        //Make room for .site-search__trigger on mobile.
        padding-bottom: 2.5rem;
    }

    .header__main--global {
        padding-top: .5rem;
        padding-bottom: .5rem;
        align-items: center;
    }

        .header__logo--global {
            max-width: 8rem;
            margin: auto;
        }

        .header__logo--no-menu {
            max-width: 7.125rem;
        }

        .header__hamburger-trigger {
            //Add a bit more tap area for our mobile friends.
            padding: 0.4rem 1rem;
            margin-left: -1rem;

            //Prevent focusing on this element when the mobile menu is visible.
            &.toggle--active {
                display: none;
            }
        }
}
