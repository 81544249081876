
@include media-query(small-up) {
    .subscription-modal__content {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}

@include media-query(small) {
    .subscription-modal__content {
        padding-top: 1rem;
        padding-bottom: 4rem;
    }
}
