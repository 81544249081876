
.quickview__main {
    // padding-top: 1.25rem;
    // padding-bottom: 1.25rem;
}

    .quickview__attribute-list {
        margin-top: 1.5rem;
    }

        .quickview__promotions {
            margin-bottom: .75rem;
        }

.quickview__footer {
    margin-top: 2rem;
}

@include media-query(small-up) {
    
}

@include media-query(small) {
    .quickview__main {
        margin-top: 1.25rem;
    }
}

.quickview__main {
    .affirm-as-low-as {
        padding: 0 1rem;
    }
}

.pdp-main__price {
    margin: 1rem 0;
}
