@import  'slick-carousel/slick/slick.scss';

//Add this class if you need to flex-ify Slick.
.slider--flex {
    display: flex;

    .slick-list {
        flex: 1 0 100%;
    }

    .slick-track {
        height: 100%;
        min-width: 100%;
    }
}

//Used to vertically align items within a flex slider.
.slider--flex-align-items {
    &.slick-initialized {
        .slick-slide {
            flex-basis: auto;
            height: auto;
        }
    }

    .slick-track {
        display: flex;
    }
}

.slick-arrow {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 3.125rem;
    height: 3.125rem;
    font-size: 1.75rem;
    cursor: pointer;
}

.slick-prev {
    left: 0;
}

.slick-next {
    right: 0;
}

.slider--arrows-outer {
    &.slick-has-arrows {
        &.slider--arrows-size-large {
            padding-left: 4.5rem;
            padding-right: 4.5rem;
        }

        &.slider--arrows-size-regular,
        &.slider--arrows-size-small {
            padding-left: 3.125rem;
            padding-right: 3.125rem;
        }
    }
}

.slider--arrows-center {
    .slick-arrow {
        @include vertical-align($transform: true, $posAdj: var(--arrows-adjustment-y, 0px));
    }
}

.slider--arrows-tile-center,
.top-filter {
    .slick-arrow {
        top: 25%;
    }
}

.slider--arrows-size-large {
    .slick-arrow {
        width: 4.5rem;
        height: 4.5rem;
        font-size: 3rem;
    }
}

.slider--arrows-size-regular {
    .slick-arrow {
        
    }
}

.slider--arrows-size-small {
    .slick-arrow {
        font-size: 1.125rem;
    }
}


.slick-dots {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding-left: 0;

    .slick-active {
        button:after {
            background-color: currentColor;
        }
    }

    li {
        font-size: 0;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 1.5rem;
        height: 1.85rem;
        border-radius: 50%;
        font-size: 0;

        &:after {
            display: block;
            width: .625rem;
            height: .625rem;
            border-radius: inherit;
            border: solid .0625rem;
            content: '';
        }
    }
}

.slider--dots-outer {
    .slick-dots {
        margin-top: 1rem;
    }
}

.slider--dots-inner {
    .slick-dots {
        position: absolute;
        left: 0;
        right: 0;
    }
}

.slider--dots-left {
    .slick-dots {
        justify-content: flex-start;
    }
}

.slider--dots-right {
    .slick-dots {
        justify-content: flex-end;
    }
}


[class*="slider--pre-layout"] {
    &:not(.slick-initialized) {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
    }
}


//Provide a "pre init" layou for your carousels.
//This is helpful to avoid the page from jumping around while the carousel is ready.
//breakpoint-specific layouts are available further down.
@for $i from 1 through 8 {
    .slider--pre-layout-#{$i} {
        &:not(.slick-initialized) {
            > *:not(.slick-item) {
            flex: $i 0 percentage(1/$i);
        }
        }
    }
}

@include media-query(medium-up) {
    @for $i from 1 through 8 {
        .slider--pre-layout-lg-#{$i} {
            &:not(.slick-initialized) {
                > *:not(.slick-item) {
                flex: $i 0 percentage(1/$i);
            }
            }
        }
    }

    //Add this class if you want to Slick to support the row/col grid.
    .slider--row {
        &:not(.slick-initialized),
        .slick-list {
            margin-left: -$gutter-inner;
            margin-right: -$gutter-inner;
        }
    }
}

@include media-query(medium) {
    //Add this class if you want to Slick to support the row/col grid.
    .slider--row {
        &:not(.slick-initialized),
        .slick-list {
            margin-left: -$gutter-inner-deci;
            margin-right: -$gutter-inner-deci;
        }
    }
}

@include media-query(small-up) {
    .slider--dots-inner {
        .slick-dots {
            bottom: 2.975rem;
        }
    }

    .experience-accelerator_layouts-flexibleCarousel {
        .module-carousel .slick-dots {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 1rem;
            margin-top: 0;
        }
    }
    
    .slick-initialized {
        &:not(.promo-banner-carousel) {
            .slick-slide {
                height: 100vh;
                img {
                    margin: auto;
                }
            }
        }
    }

    @for $i from 1 through 8 {
        .slider--pre-layout-md-#{$i} {
            &:not(.slick-initialized) {
                > *:not(.slick-item) {
                flex: $i 0 percentage(1/$i);
            }
            }
        }
    }
}

@include media-query(small) {
    .slider--dots-inner {
        .slick-dots {
            bottom: .75rem;
        }
    }
}

body .slick-slider {
    -ms-touch-action: auto;
    touch-action: auto;
}

.einstein-carousel-custom, .recommendation-carousel{
    .slick-initialized .slick-slide {
        // @media screen and (min-width: 64.0625rem) {
            height: 100%;
        // }
    }
}


.top-filter .slick-arrow {
    width: 1.5rem;
    height: 1.5rem;

    &.slick-prev{
        font-size: 0;
        background: rgba(255,255,255, 0.72) url(../images/icon-arrow-left.svg) no-repeat center center;
    }

    &.slick-next {
        font-size: 0;
        background: rgba(255,255,255, 0.72) url(../images/icon-arrow-right.svg) no-repeat center center;
    }
}

.search-result-null--suggestions {
    .slick-initialized .slick-slide {
        height: 100% !important;
    }
}

.header-promo {
    overflow: hidden;

    .promo-banner-carousel {

        &.max-height{
            height: 30px;
            overflow: hidden;
        }

        padding: 4px 24px;
        justify-content: center;
        
        @media (max-width: 769px) {
            padding: 6px 42px;
            box-sizing: border-box;
            text-align: center;
        }

        .slick-track{
            display: flex;
            flex-flow: nowrap;
            vertical-align: middle;
            align-items: center;
        }

        .slick-arrow {
            width: 1.5rem;
            height: 1.5rem;
        
            &.slick-prev{
                font-size: 0;
                background: url(../images/icon-arrow-left-white.svg) no-repeat center center;
                background-size: 20px;
            }
        
            &.slick-next {
                font-size: 0;
                background: url(../images/icon-arrow-right-white.svg) no-repeat center center;
                background-size: 20px;
            }
        }

        button.slick-prev.slick-arrow {
            left: 0;
            height: 100%;
            background-repeat: no-repeat;
        }

        button.slick-next.slick-arrow {
            background-repeat: no-repeat;
            height: 100%;
            @media (max-width: 769px) {
                right: 0;
            }
        }

        .slick-next::before,
        .slick-prev::before {
            color: #fff;
            display: none;
        }
    }

}
