

    .footer-content-grid__item {
        border: solid .0625rem $color-grey-2;
    }

    .footer-content-grid__actions {
        margin-top: 1.75rem;
    }

        .footer-content-grid__action {
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }


@include media-query(small-up) {
    .footer-content-grid {
        margin-top: 3.25rem;
        margin-bottom: 4.25rem;
    }

        .footer-content-grid__item {
            padding: 4.0625rem 2.5rem;
        }
}

@include media-query(small) {
    .footer-content-grid {
        margin-top: .875rem;
        margin-bottom: 2.25rem;
    }

        .footer-content-grid__item {
            padding: 2.75rem 1.75rem;
            margin-bottom: 1.875rem;
        }
}