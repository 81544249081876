
.html-block--size-large {
    font-size: $font-size-deka;
}

.html-block--size-small {
    font-size: $font-size-deci;
}

.html-block__copy:not(:only-child) {
    &.set--text-after {
        margin-top: 2rem;
    }

    &.set--text-before {
        margin-bottom: 2rem;
    }
}
.html-block__body {
    margin-bottom: 1rem;
}

.html-block__actions {
    &:not(:only-child) {
        margin-top: 2rem;
    }

    margin-right: -.5rem;
    margin-left: -.5rem;
}

    .html-block__cta {
        margin-right: .5rem;
        margin-left: .5rem;

        &:not(:last-child) {
            margin-bottom: 1rem
        }
    }

@include media-query(small-up) {
    .html-block,
    .html-block-list__item {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }

        .html-block__copy:not(:only-child) {
            &.set--text-after-large {
                margin-top: 2rem;
            }

            &.set--text-before-large {
                margin-bottom: 2rem;
            }
        }
}

@include media-query(small) {
    .html-block,
    .html-block-list__item {
        &:not(:last-child) {
            margin-bottom: 3rem;
        }
    }

        .html-block__copy:not(:only-child) {
            &.set--text-after-small {
                margin-top: 2rem;
            }

            &.set--text-before-small {
                margin-bottom: 2rem;
            }
        }
}
.image-right {
    img {
        margin-left: auto;
    }
}
.image-center {
    img {
        margin: 0 auto;
    }
}